import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus,
} from 'types';
import { 
  assignCardBlockApi,
} from 'api';
import { 
  blockSetStatus, blockAssignCard, blockGetData,
} from 'store/block/actionCreators';

export function* blockAssignCardSaga({ type, payload }: ReturnType<typeof blockAssignCard>) {
  try{
    yield put(blockSetStatus({ type, status: RequestStatus.REQUEST }));

    yield call(assignCardBlockApi, payload.blockId, payload.cardId);
    if(payload.callback) payload.callback();

    yield put(blockGetData());
    yield put(blockSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(blockSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
