import React, { FC, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { leaderGetData } from 'store/leader/actionCreators';
import { leaderSelectors } from 'store/leader/selectors';
import { getShortAddress } from 'utils';
import { LeaderBoardItem } from './LeaderBoardItem';
import styles from './styles.module.css';

export interface LeaderBoardProps {
  className?: string
}

export const LeaderBoard: FC<LeaderBoardProps> = ({ className = '' }) => {
  const dispatch = useDispatch();

  const leaderBoard = useSelector(leaderSelectors.getProp('leaderBoard'));
  
  useEffect(() => {
    dispatch(leaderGetData());
  }, [dispatch]);

  return (
    <div className={cx(styles.leaderBoard, className)}>
      <span className={styles.title}>
        LeaderBoard
      </span>

      {leaderBoard.map((item, index) => (
        <LeaderBoardItem
          key={item.address}
          count={`0${index + 1}`}
          name={getShortAddress(item.address, 6) ?? ''}
          leader={item}
        />
      ))}
    </div>
  );
};
