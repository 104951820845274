import { getAddress, getContract } from 'api/ethereum';
import { BlockAbi } from 'assets/abi';
import { contracts } from 'appConstants';

export const balanceOfBlockApi = async (): Promise<string> => {
  const contract = getContract(contracts.Block, BlockAbi);
  const address = await getAddress(); 

  return contract.methods
    .balanceOf(address)
    .call();
};

export const getBlockInfoBlockApi = async (blockId: number): Promise<{
  cityId: number, cardId: number,
}> => {
  const contract = getContract(contracts.Block, BlockAbi);

  return contract.methods
    .getBlockInfo(blockId)
    .call();
};

export const getBlockPriceBlockApi = async (): Promise<number> => {
  const contract = getContract(contracts.Block, BlockAbi);

  return contract.methods
    .getBlockPrice()
    .call();
};

export const getRewardsBlockApi = async (tokenId: number): Promise<number> => {
  const contract = getContract(contracts.Block, BlockAbi);

  return contract.methods
    .getRewards(tokenId)
    .call();
};

export const getRewardsPaidBlockApi = async (cardId: number): Promise<number> => {
  const contract = getContract(contracts.Block, BlockAbi);

  return contract.methods
    .getRewardsPaid(cardId)
    .call();
};

export const withdrawETHBlockApi = async (member: string, amount: number) => {
  const contract = getContract(contracts.Block, BlockAbi);
  const address = await getAddress();

  return contract.methods
    .withdrawETH(member, amount)
    .send({ from: address });
};

export const withdrawRewardsBlockApi = async (blockId: number, amount: number) => {
  const contract = getContract(contracts.Block, BlockAbi);
  const address = await getAddress();

  return contract.methods
    .withdrawRewards(blockId, amount)
    .send({ from: address });
};

export const withdrawAllRewardsBlockApi = async () => {
  const contract = getContract(contracts.Block, BlockAbi);
  const address = await getAddress();

  return contract.methods
    .withdrawAllRewards()
    .send({ from: address });
};

export const mintWithCardBlockApi = async (cardId: number) => {
  const contract = getContract(contracts.Block, BlockAbi);
  const address = await getAddress();

  return contract.methods
    .mintWithCard(cardId)
    .send({ from: address });
};

export const assignCardBlockApi = async (blockId: number, cardId: number) => {
  const contract = getContract(contracts.Block, BlockAbi);
  const address = await getAddress();

  return contract.methods
    .assignCard(blockId, cardId)
    .send({ from: address });
};

export const removeCardBlockApi = async (blockId: number, cardId: number) => {
  const contract = getContract(contracts.Block, BlockAbi);
  const address = await getAddress();

  return contract.methods
    .removeCard(blockId, cardId)
    .send({ from: address });
};
