import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'types';
import { ChooseItemsModal } from 'components';
import { IconSettingImg } from 'assets/img';
import { contracts } from 'appConstants';
import { gearsClaim } from 'store/gears/actionCreators';
import { GearsActionTypes } from 'store/gears/actionsTypes';
import { MultiPassActionTypes } from 'store/multiPass/actionsTypes';
import { multiPassApprove } from 'store/multiPass/actionCreators';
import { gearsSelectors } from 'store/gears/selectors';
import { multiPassSelectors } from 'store/multiPass/selectors';

export const useClaimGears = () => {
  const dispatch = useDispatch();
  
  const statusClaim = useSelector(gearsSelectors.getStatus(GearsActionTypes.Claim));
  const statusApprove = useSelector(multiPassSelectors.getStatus(MultiPassActionTypes.Approve));
  const isApproveForGears = useSelector(multiPassSelectors.getProp('isApproveForGears'));

  const [multiPassIds, setMultiPassIds] = useState<number[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  
  const handleFistStepOpen = useCallback((id?: number) => {
    setOpenModal(true);
    if (id) setMultiPassIds([id]);
  }, []);

  const handleFistStepClose = useCallback(() => {
    setOpenModal(false); 
    setMultiPassIds([]);
  }, []);

  const multiPassMetas = useSelector(multiPassSelectors.getProp('multiPassMetas'));

  const multiPass = useMemo(() => 
    multiPassMetas
      .filter((item) => !item.isClaimedGear && item?.level && +item.level >= 2)
      .map((item) => ({
        id: item.id,
        label: `// ID ${item.id}`,
        value: `// Lvl ${item.level}`,
        img: item.imageUrl,
      })), [multiPassMetas]);
  
  const handleClaim = useCallback((multiPassIdsNew: number[]) => {
    if(multiPassIdsNew.length) {
      dispatch(gearsClaim({
        multiPassIds: multiPassIdsNew,
        callback: handleFistStepClose,
      }));
    }
  }, [dispatch, handleFistStepClose]);

  const handleApprove = useCallback(() => {
    dispatch(multiPassApprove({ address: contracts.Gears }));
  }, [dispatch]);

  return {
    openClaimGears: handleFistStepOpen,
    modalClaimGears: [
      <ChooseItemsModal
        key="useClaimGears-CLAIM GEAR"
        buttonLabel="CLAIM GEAR"
        buttonImg={IconSettingImg}
        items={multiPass}
        label={`You can claim a Gear Token for each MultiPass at level 2 or above.
        Select your MultiPass(es) below.
        `}
        title="CLAIM GEAR TOKEN(S)"
        isOpen={openModal}
        onClose={handleFistStepClose}
        onButtonClick={handleClaim}
        defaultIds={multiPassIds}
        isOnlyOne={false}
        onChoose={setMultiPassIds}
        buttonIsDisabled={!isApproveForGears || multiPassIds.length === 0}
        buttonIsLoading={statusClaim === RequestStatus.REQUEST}
        buttonSecondLabel="Approve"
        onButtonSecondClick={!isApproveForGears ? handleApprove : undefined}
        buttonSecondIsDisabled={multiPassIds.length === 0}
        buttonSecondIsLoading={statusApprove === RequestStatus.REQUEST}
      />,
    ],
  };
};
