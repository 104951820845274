import React, { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { Navigation, Links } from 'components';
import { Header } from 'containers';
import styles from './styles.module.css';

type PropsLayout = {
  leftPanel?: ReactNode,
};
export const Layout: FC<PropsLayout> = ({ leftPanel }) => (
  <div className={styles.container}>
    <Header
      className={styles.header}
    />
    <Navigation className={styles.nav} />
    <Links className={styles.links} />
    <div className={styles.leaderBoard}>
      {leftPanel}
    </div>
    <div className={styles.body}>
      <Outlet />
    </div>
  </div>
);
