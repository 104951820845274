import createReducer from 'utils/createReducer';
import { RequestStatus } from 'types';
import { RewardsState } from 'types/store/RewardsState';
import { RewardsActionTypes } from './actionsTypes';
import { rewardsHandlers } from './handlers';

export const rewardsInitialState: Readonly<RewardsState> = {
  rewards: {
    blocks: [],
    citizenCards: [],
  },
  balanceRewardsAll: '0',
  ui: {
    [RewardsActionTypes.GetData]: RequestStatus.INIT,
  },
};

export default createReducer(rewardsInitialState, rewardsHandlers);
