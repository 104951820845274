import React, { FC } from 'react';
import cx from 'classnames';
import { LeaderBoard } from 'types';
import { UserImg } from 'assets/img';
import { ParameterItem } from '../ParameterItem';
import styles from './styles.module.css';

interface LeaderBoardItemProps {
  className?: string
  count: string,
  name: string,
  leader: LeaderBoard, 
}

export const LeaderBoardItem: FC<LeaderBoardItemProps> = ({ 
  className = '', count, name, leader,
}) => (
  <div className={cx(styles.leaderBoardItem, className)}>
    <span className={styles.count}>
      {count}
    </span>
    <img
      src={UserImg}
      alt="user"
      className={styles.userImg}
    />

    <div className={styles.infoBlock}>
      <span className={styles.userName}>
        {name}
      </span>
      <div className={styles.parameters}>
        <ParameterItem
          name="AV"
          value={`${leader.avatar}`}
        />
        <ParameterItem
          name="BL"
          value={`${leader.block}`}
        />
        <ParameterItem
          name="Ci"
          value={`${leader.citizenCard}`}
        />
        <ParameterItem
          name="AB"
          value={`${leader.balance}`}
          isLastParam
        />
      </div>

    </div>
  </div>
);
