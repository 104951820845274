export default [{ inputs: [{ internalType: 'address', name: '_multipass', type: 'address' }], stateMutability: 'nonpayable', type: 'constructor' }, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'uint256', name: 'avatarID', type: 'uint256', 
  }, {
    indexed: false, internalType: 'address', name: 'holder', type: 'address', 
  }, {
    indexed: false, internalType: 'uint256', name: 'oldGearID', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint256', name: 'newGearID', type: 'uint256', 
  }],
  name: 'ApplyGear',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'account', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'operator', type: 'address', 
  }, {
    indexed: false, internalType: 'bool', name: 'approved', type: 'bool', 
  }],
  name: 'ApprovalForAll',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'uint256', name: 'avatarID', type: 'uint256', 
  }, {
    indexed: false, internalType: 'address', name: 'operator', type: 'address', 
  }, {
    indexed: false, internalType: 'address', name: 'holder', type: 'address', 
  }, {
    indexed: false, internalType: 'uint256', name: 'fromCityID', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint256', name: 'toCityID', type: 'uint256', 
  }],
  name: 'CityChanged',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'operator', type: 'address', 
  }, {
    indexed: false, internalType: 'bool', name: 'hasApproval', type: 'bool', 
  }],
  name: 'DefaultApproval',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'uint256', name: 'avatarID', type: 'uint256', 
  }, {
    indexed: true, internalType: 'uint256', name: 'burnedMultipassID', type: 'uint256', 
  }, {
    indexed: true, internalType: 'address', name: 'to', type: 'address', 
  }, {
    indexed: false, internalType: 'uint256', name: 'initLevel', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint256', name: 'citySerial', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint256', name: 'personalSerial', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint256', name: 'randReceipt', type: 'uint256', 
  }],
  name: 'Minted',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'previousOwner', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'newOwner', type: 'address', 
  }],
  name: 'OwnershipTransferred',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'maxMintingPerTime', type: 'uint256', 
  }],
  name: 'SetMaxMintingPerTime',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'uint256', name: 'avatarID', type: 'uint256', 
  }, {
    indexed: true, internalType: 'address', name: 'owner', type: 'address', 
  }, {
    indexed: false, internalType: 'uint256', name: 'fromLevel', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint256', name: 'toLevel', type: 'uint256', 
  }],
  name: 'TokenUpgrade',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'operator', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'from', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'to', type: 'address', 
  }, {
    indexed: false, internalType: 'uint256[]', name: 'ids', type: 'uint256[]', 
  }, {
    indexed: false, internalType: 'uint256[]', name: 'values', type: 'uint256[]', 
  }],
  name: 'TransferBatch',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'operator', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'from', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'to', type: 'address', 
  }, {
    indexed: false, internalType: 'uint256', name: 'id', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint256', name: 'value', type: 'uint256', 
  }],
  name: 'TransferSingle',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'string', name: 'value', type: 'string', 
  }, {
    indexed: true, internalType: 'uint256', name: 'id', type: 'uint256', 
  }],
  name: 'URI',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: '_operator', type: 'address', 
  }, {
    indexed: false, internalType: 'uint256', name: '_ethWei', type: 'uint256', 
  }],
  name: 'WithdrawEth',
  type: 'event', 
}, { stateMutability: 'payable', type: 'fallback' }, {
  inputs: [{ internalType: 'uint32', name: 'avatarId', type: 'uint32' }, { internalType: 'address', name: 'holder', type: 'address' }, { internalType: 'uint32', name: 'gearID', type: 'uint32' }], name: 'applyGear', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'account', type: 'address' }, { internalType: 'uint256', name: 'id', type: 'uint256' }], name: 'balanceOf', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address[]', name: 'accounts', type: 'address[]' }, { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' }], name: 'balanceOfBatch', outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address[]', name: '_addrs', type: 'address[]' }, { internalType: 'uint8[]', name: '_levels', type: 'uint8[]' }], name: 'batchMint', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint32', name: 'avatarId', type: 'uint32' }, { internalType: 'address', name: 'holder', type: 'address' }, { internalType: 'uint32', name: 'cityID', type: 'uint32' }], name: 'changeCity', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [], name: 'currentMinted', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'decimals', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: '_addr', type: 'address' }, { internalType: 'uint256', name: '_id', type: 'uint256' }], name: 'existsToken', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }, { internalType: 'address', name: '_addr', type: 'address' }],
  name: 'getTokenInfo',
  outputs: [{
    components: [{ internalType: 'uint8', name: 'level', type: 'uint8' }, { internalType: 'uint8', name: 'reverse1', type: 'uint8' }, { internalType: 'uint16', name: 'reverse2', type: 'uint16' }, { internalType: 'uint32', name: 'amount', type: 'uint32' }, { internalType: 'uint32', name: 'gearID', type: 'uint32' }, { internalType: 'uint32', name: 'mintedAtTimestamp', type: 'uint32' }, { internalType: 'uint32', name: 'cityID', type: 'uint32' }, { internalType: 'uint32', name: 'personalID', type: 'uint32' }, { internalType: 'uint32', name: 'randReceipt', type: 'uint32' }, { internalType: 'uint32', name: 'reverse4', type: 'uint32' }], internalType: 'struct ERC1155.TokenInfo', name: '', type: 'tuple', 
  }],
  stateMutability: 'view',
  type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: '_owner', type: 'address' }, { internalType: 'address', name: '_operator', type: 'address' }], name: 'isApprovedForAll', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'lockSupplyForLifeTime', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint16[]', name: 'multipassIds', type: 'uint16[]' }], name: 'mintAvatar', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'account', type: 'address' }, { internalType: 'uint16[]', name: 'multipassIds', type: 'uint16[]' }], name: 'mintAvatar', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [], name: 'mintingStatus', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'multipassAddress', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'name', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'pure', type: 'function', 
}, {
  inputs: [], name: 'nftToken', outputs: [{ internalType: 'uint32', name: 'mintingStatus', type: 'uint32' }, { internalType: 'uint32', name: 'totalSupply', type: 'uint32' }, { internalType: 'uint32', name: 'currentMinted', type: 'uint32' }, { internalType: 'uint32', name: 'lockSupply', type: 'uint32' }, { internalType: 'uint32', name: 'maxSupply', type: 'uint32' }, { internalType: 'uint32', name: 'reserve1', type: 'uint32' }, { internalType: 'uint32', name: 'reserve2', type: 'uint32' }, { internalType: 'uint32', name: 'reserve3', type: 'uint32' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'from', type: 'address' }, { internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' }, { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }, { internalType: 'bytes', name: 'data', type: 'bytes' }], name: 'safeBatchTransferFrom', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'from', type: 'address' }, { internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'id', type: 'uint256' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }, { internalType: 'bytes', name: 'data', type: 'bytes' }], name: 'safeTransferFrom', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'operator', type: 'address' }, { internalType: 'bool', name: 'approved', type: 'bool' }], name: 'setApprovalForAll', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'operator', type: 'address' }, { internalType: 'bool', name: 'hasApproval', type: 'bool' }], name: 'setDefaultApproval', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint32', name: '_status', type: 'uint32' }], name: 'setMintingStatus', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'string', name: '_uri', type: 'string' }, { internalType: 'uint256', name: '_id', type: 'uint256' }], name: 'setTokenURI', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint32', name: '_supply', type: 'uint32' }], name: 'setTotalSupply', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'string', name: 'newuri', type: 'string' }], name: 'setUri', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }], name: 'supportsInterface', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'symbol', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'pure', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'account', type: 'address' }, { internalType: 'uint256', name: 'id', type: 'uint256' }],
  name: 'tokenOf',
  outputs: [{
    components: [{ internalType: 'uint8', name: 'level', type: 'uint8' }, { internalType: 'uint8', name: 'reverse1', type: 'uint8' }, { internalType: 'uint16', name: 'reverse2', type: 'uint16' }, { internalType: 'uint32', name: 'amount', type: 'uint32' }, { internalType: 'uint32', name: 'gearID', type: 'uint32' }, { internalType: 'uint32', name: 'mintedAtTimestamp', type: 'uint32' }, { internalType: 'uint32', name: 'cityID', type: 'uint32' }, { internalType: 'uint32', name: 'personalID', type: 'uint32' }, { internalType: 'uint32', name: 'randReceipt', type: 'uint32' }, { internalType: 'uint32', name: 'reverse4', type: 'uint32' }], internalType: 'struct ERC1155.TokenInfo', name: '', type: 'tuple', 
  }],
  stateMutability: 'view',
  type: 'function', 
}, {
  inputs: [], name: 'totalSupply', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }], name: 'transferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'account', type: 'address' }, { internalType: 'uint32[]', name: 'avatarIds', type: 'uint32[]' }, { internalType: 'uint16[]', name: 'multipassIds', type: 'uint16[]' }], name: 'updateAvatar', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint32[]', name: 'avatarIds', type: 'uint32[]' }, { internalType: 'uint16[]', name: 'multipassIds', type: 'uint16[]' }], name: 'updateAvatar', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'uri', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'withdraw', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, { stateMutability: 'payable', type: 'receive' }];
