import { ActionFn } from 'types/redux';
import { MultiPassState } from 'types/store/MultiPassState';
import { multiPassSetState, multiPassSetStatus } from './actionCreators';
import { MultiPassActionTypes } from './actionsTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MultiPassHandlerFn<F extends (...args : any) => any> = ActionFn<MultiPassState, ReturnType<F>>;

const setState: MultiPassHandlerFn<typeof multiPassSetState> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: MultiPassHandlerFn<typeof multiPassSetStatus> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const multiPassHandlers = {
  [MultiPassActionTypes.SetState]: setState,
  [MultiPassActionTypes.SetStatus]: setStatus,
};
