import { takeLeading } from 'redux-saga/effects';
import { AvatarActionTypes } from '../actionsTypes';
import { avatarGetDataSaga } from './getData';
import { avatarUpdateSaga } from './update';
import { avatarMintSaga } from './mint';
import { avatarApproveSaga } from './approve';

export default function* avatarSaga() {
  yield takeLeading(AvatarActionTypes.GetData, avatarGetDataSaga);
  yield takeLeading(AvatarActionTypes.Update, avatarUpdateSaga);
  yield takeLeading(AvatarActionTypes.Mint, avatarMintSaga);
  yield takeLeading(AvatarActionTypes.Approve, avatarApproveSaga);
}
