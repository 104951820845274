import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus,
} from 'types';
import { approveMultiPassApi } from 'api';
import { 
  multiPassSetStatus, multiPassApprove, multiPassGetData,
} from 'store/multiPass/actionCreators';

export function* multiPassApproveSaga({ type, payload }: ReturnType<typeof multiPassApprove>) {
  try{
    yield put(multiPassSetStatus({ type, status: RequestStatus.REQUEST }));

    yield call(approveMultiPassApi, payload.address, true);
    yield put(multiPassGetData());

    yield put(multiPassSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(multiPassSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
