import axios from 'axios';
import { apiBaseUrl } from 'appConstants';

export const api = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'Access-Control-Allow-Origin': '*',
  },
});

export * from './auth';
export * from './apiInterceptors';
export * from './profile';
export * from './leaderboard';
export * from './rewards';
export * from './signature';
