import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus,
} from 'types';
import { updateAvatarApi } from 'api';
import { 
  avatarSetStatus, avatarUpdate, avatarGetData,
} from 'store/avatar/actionCreators';
import { multiPassGetData } from 'store/multiPass/actionCreators';

export function* avatarUpdateSaga({ type, payload }: ReturnType<typeof avatarUpdate>) {
  try{
    yield put(avatarSetStatus({ type, status: RequestStatus.REQUEST }));

    yield call(updateAvatarApi, payload.avatarIds, payload.multiPassIds);

    if(payload.callback) payload.callback();
    yield put(multiPassGetData());
    yield put(avatarGetData()); 
    yield put(avatarSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(avatarSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
