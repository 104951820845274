import { ActionFn } from 'types/redux';
import { CitizenCardState } from 'types/store/CitizenCardState';
import { citizenCardSetState, citizenCardSetStatus } from './actionCreators';
import { CitizenCardActionTypes } from './actionsTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, max-len
type CitizenCardHandlerFn<F extends (...args : any) => any> = ActionFn<CitizenCardState, ReturnType<F>>;

const setState: CitizenCardHandlerFn<typeof citizenCardSetState> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: CitizenCardHandlerFn<typeof citizenCardSetStatus> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const citizenCardHandlers = {
  [CitizenCardActionTypes.SetState]: setState,
  [CitizenCardActionTypes.SetStatus]: setStatus,
};
