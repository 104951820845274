export const routes = {
  dashboard: {
    root: '/',
    title: 'Dashboard',
  },
  marketplace: {
    root: '/marketplace',
    title: 'Marketplace',
  },
  idCard: {
    root: '/idCard',
    title: 'Awakened ID Card',
  },
  blocks: {
    root: '/blocks',
    title: 'Blocks',
  },
  gears: {
    root: '/gears',
    title: 'Gears',
  },
  multiPass: {
    root: '/multiPass',
    title: 'MultiPasses',
  },
  convert: {
    root: '/convert',
    title: 'Exit Containment',
  },
};
