import createReducer from 'utils/createReducer';
import { RequestStatus } from 'types';
import { GearsState } from 'types/store/GearsState';
import { GearsActionTypes } from './actionsTypes';
import { gearsHandlers } from './handlers';

export const gearsInitialState: Readonly<GearsState> = {
  balance: '',
  gearsMetas: [],
  ui: {
    [GearsActionTypes.GetData]: RequestStatus.INIT,
    [GearsActionTypes.Claim]: RequestStatus.INIT,
    [GearsActionTypes.ApplyToAvatar]: RequestStatus.INIT,
  },
};

export default createReducer(gearsInitialState, gearsHandlers);
