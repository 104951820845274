import { takeLeading } from 'redux-saga/effects';
import { CitizenCardActionTypes } from '../actionsTypes';
import { citizenCardGetDataSaga } from './getData';
import { citizenCardMintSaga } from './mint';
import { citizenCardUpgradeSaga } from './upgrade';
import { citizenCardWithdrawRewardsSaga } from './withdraw';

export default function* citizenCardSaga() {
  yield takeLeading(CitizenCardActionTypes.GetData, citizenCardGetDataSaga);
  yield takeLeading(CitizenCardActionTypes.Mint, citizenCardMintSaga);
  yield takeLeading(CitizenCardActionTypes.Upgrade, citizenCardUpgradeSaga);
  yield takeLeading(CitizenCardActionTypes.WithdrawRewards, citizenCardWithdrawRewardsSaga);
}
