import React, { 
  FC, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Welcome, NftList } from 'components';
import {
  ArrowImg, 
} from 'assets/img';
import {
  useAuth, useAssignBlock, useClaimCard, useUpdateCard,
} from 'hooks';
import { citizenCardGetData } from 'store/citizenCard/actionCreators';
import { citizenCardSelectors } from 'store/citizenCard/selectors';
import { blockGetData } from 'store/block/actionCreators';
import { ClaimTokens } from 'containers';
import { linksOpensea, linksOpenseaForItem } from 'appConstants';

export const CitizenCard: FC = () => {
  const dispatch = useDispatch();

  const { modalAssignBlock, openAssignBlock } = useAssignBlock();
  const { modalClaimCard, openClaimCard } = useClaimCard();
  const { modalUpgradeCards, openUpgradeCards } = useUpdateCard();

  const { isUserAuthenticated, addressWallet } = useAuth();

  const citizenCardMetas = useSelector(citizenCardSelectors.getProp('citizenCardMetas'));

  useEffect(() => {
    if (isUserAuthenticated) {
      dispatch(blockGetData());
      dispatch(citizenCardGetData());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUserAuthenticated, addressWallet]);

  const linksCitizenCard = useMemo(() => [
    { name: 'Upgrade', method: () => openUpgradeCards() },
    { name: 'Assign block', method: () => openAssignBlock() },
    { 
      name: 'Sell ID Card', 
      method: () => window.open(
        `${linksOpensea.CitizenCard}`, 
        '_blank',
      ),
    },
    { name: 'Claim Earnings', method: () => openClaimCard() },
  ], [openAssignBlock, openClaimCard, openUpgradeCards]);

  const actionsCitizenCard = useMemo(() => [
    { nameAction: 'ASSIGN BLOCK', imgAction: ArrowImg, action: (id?: number) => openAssignBlock(id) },
    { nameAction: 'REMOVE BLOCK', imgAction: ArrowImg, action: () => {} },
    { 
      nameAction: 'SELL CARD', 
      imgAction: ArrowImg, 
      action: (id?: number) => window.open(
        `${linksOpenseaForItem.CitizenCard}/${id}`, 
        '_blank',
      ),
    },
    { nameAction: 'CLAIM EARNING', imgAction: ArrowImg, action: (id?: number) => openClaimCard(id) },
  ], [openAssignBlock, openClaimCard]);

  return (
    <>
      <Welcome
        title="ID CARDS" 
        description={`
      The Avatar-ID card is the ultimate way to showcase your status. 
      After your visit to Metacorp, you'll receive an updated A-ID card that represents your unique cybernetic enhancements.
      Not only is the Avatar ID card a badge of honour, but it's also a practical tool for navigating the dangerous Awakened Universe. With your ID card, you can quickly and easily verify your identity to other agents, gain access to secure locations, and manage your portfolio of assets.
      So whether you're a seasoned veteran or just starting out, make sure you have an Avatar ID card in your arsenal. It's the key to unlocking the full potential of your cybernetic enhancements and rising to the top of the Awakened hierarchy.
      `}
      />
      <ClaimTokens />
      <NftList
        key="A-ID CARDS"
        name="A-ID CARDS"
        titleModal="A-ID CARD"
        links={linksCitizenCard} 
        isDisabledLinks={!isUserAuthenticated || !citizenCardMetas.length}
        actions={actionsCitizenCard}
        images={citizenCardMetas}
      />
      {modalAssignBlock}
      {modalClaimCard}
      {modalUpgradeCards}
    </>
  );
};
