import { fork } from 'redux-saga/effects';
// sagas
import rewardsSaga from './rewards/sagas';
import leaderSaga from './leader/sagas';
import AuthSaga from './auth/sagas';
import blockSaga from './block/sagas';
import citizenCardSaga from './citizenCard/sagas';
import awakenedTokenSaga from './awakenedToken/sagas';
import gearsSaga from './gears/sagas';
import multiPassSaga from './multiPass/sagas';
import avatarSaga from './avatar/sagas';
import metamaskSaga from './metamask/sagas';

export default function* rootSaga() {
  yield fork(rewardsSaga);
  yield fork(leaderSaga);
  yield fork(AuthSaga);
  yield fork(blockSaga);
  yield fork(citizenCardSaga);
  yield fork(awakenedTokenSaga);
  yield fork(gearsSaga);
  yield fork(multiPassSaga);
  yield fork(avatarSaga);
  yield fork(metamaskSaga);
  //
}
