import { ApiEndpoint } from 'appConstants';
import { throwApiError } from 'utils';
import { api } from 'api';

export const apiAuthNonce = async (
  address: string,
): Promise<{
  token: string,
}> => {
  const { data } = await api.post(
    `${ApiEndpoint.AuthNonce}`,
    {
      address,
    },
  );

  if (data.error) {
    throwApiError(data);
  }

  return {
    token: data.token,
  };
};

export const apiAuthLogin = async (
  token: string,
  signature: string,
): Promise<{
  accessToken: string,
  refreshToken: string,
}> => {
  const { data } = await api.post(
    `${ApiEndpoint.AuthLogin}`,
    {
      token,
      signature,
    },
  );

  if (data.error) {
    throwApiError(data);
  }

  return {
    accessToken: data.accessToken,
    refreshToken: data.refreshToken, 
  };
};

export const apiAuthLogout = async (): Promise<boolean> => {
  const { data } = await api.post(
    `${ApiEndpoint.AuthLogout}`,
  );

  if (data.error) {
    throwApiError(data);
  }

  return data;
};

export const apiAuthRefresh = async (
  refreshToken: string,
): Promise<{
  accessToken: string,
  refreshToken: string,
}> => {
  const { data } = await api.patch(
    `${ApiEndpoint.AuthRefresh}/${refreshToken}`,
    {
      refreshToken,
    },
  );

  if (data.error) {
    throwApiError(data);
  }

  return {
    accessToken: data.accessToken,
    refreshToken: data.refreshToken, 
  };
};
