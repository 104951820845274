import createReducer from 'utils/createReducer';
import { RequestStatus } from 'types';
import { CitizenCardState } from 'types/store/CitizenCardState';
import { CitizenCardActionTypes } from './actionsTypes';
import { citizenCardHandlers } from './handlers';

export const citizenCardInitialState: Readonly<CitizenCardState> = {
  balance: '',
  cardsInfo: [],
  cardsRewards: [],
  cardsRewardsPaid: [],
  citizenCardMetas: [],
  ui: {
    [CitizenCardActionTypes.GetData]: RequestStatus.INIT,
    [CitizenCardActionTypes.Mint]: RequestStatus.INIT,
    [CitizenCardActionTypes.WithdrawRewards]: RequestStatus.INIT,
  },
};

export default createReducer(citizenCardInitialState, citizenCardHandlers);
