import React, { 
  FC, useCallback, useState,
} from 'react';
import { useDeviceSelectors } from 'react-device-detect';
import cx from 'classnames';
import {
  ImageBlock, ModalWithInfo, Button, TypeButton, 
} from 'components';
import { AnimatePresence, motion } from 'framer-motion';
import { useWindowSize } from 'hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Grid } from 'swiper';
import { PaginationOptions } from 'swiper/types';
import { IconCaretImg } from 'assets/img';
import { MetaData } from 'types';
import { ButtonLinks, LinkItem } from './ButtonLinks';
import styles from './styles.module.css';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';

const maxImages = 4;
const maxImagesForSmall = 2;

interface NftListProps {
  className?: string;
  isSmallBlock?: boolean;
  name: string;
  titleModal: string;
  isDisabledLinks?: boolean;
  links: LinkItem[];
  images: MetaData[];
  actions?: { 
    nameAction: string, 
    imgAction: string, 
    action: (id?: number) => void,
  }[];
  classNameActions?: string;
}

function getAdaptIsSmall(width: number | undefined, isSmallBlock: boolean): number {
  if (width && width > 600 && width < 1050) return 3; 
  if (width && width < 600) return 2; 

  return isSmallBlock ? maxImagesForSmall : maxImages;
}

export const NftList: FC<NftListProps> = ({ 
  className = '', links, name, images, isSmallBlock, actions, classNameActions = '', titleModal, isDisabledLinks,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [activeImage, setActiveImage] = useState<MetaData | null>(null);
  const { width } = useWindowSize();
  const [selectors] = useDeviceSelectors(window.navigator.userAgent);
  const { isWindows } = selectors;

  const handleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const countActive = getAdaptIsSmall(width, isSmallBlock ?? false);

  const countEmptyImages = Math.max(
    (2 * countActive) - images.length, 
    0,
  );

  const options: PaginationOptions = {
    clickable: true,
    bulletClass: styles.bullet,
    bulletActiveClass: styles.bullet_active,
    horizontalClass: cx({
      [styles.centerBullet]: isSmallBlock,
      [styles.rightBullet]: !isSmallBlock,
    }),
  };

  return (
    <div className={cx(styles.block, { [styles.blockForSmall]: isSmallBlock }, className)}>
      <div className={styles.header}>
        <span className={styles.title}>{name}</span>
        <div className={cx(
          styles.containerCollapse, 
          { [styles.containerCollapseForSmall]: isSmallBlock },
        )}
        >
          {!isSmallBlock && (
            <button
              onClick={handleOpen}
              className={styles.button}
            >
              <motion.img
                src={IconCaretImg}
                alt="caret"
                className={styles.icon}
                initial={false}
                animate={{ rotate: isOpen ? 180 : 360 }}
                transition={{ duration: 0.5, type: 'spring' }}
                whileHover={{ scale: 1.2, opacity: 0.5 }}
              />
            </button>
          )}
        </div>
      </div>
      <AnimatePresence initial={false}>
        {isOpen && ( 
        <motion.div
          key="modalBlock"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={cx(styles.bodyBlock, {
            [styles.bodyBlockForSmall]: isSmallBlock,
          })}
        >
          <div className={styles.bgBlock} />
          <div className={styles.bgLeftBlock} />

          {isSmallBlock && (
          <ButtonLinks
            links={links}
            className={styles.buttonLinksForSmall}
            isDisabledLinks={isDisabledLinks}
          />
          )}

          <div className={cx(styles.listImages, { [styles.listImagesForSmall]: isSmallBlock })}>
            <Swiper
              slidesPerView={countActive}
              spaceBetween={5}
              pagination={options}
              grid={{
                rows: 2,
              }}
              modules={[Pagination, Grid]}
              className={styles.swiper}
            >
              {images.map((image) => (
                <SwiperSlide key={image.id}>
                  <ImageBlock
                  // className={styles.imageBlock}
                    imageUrl={image.imageUrl}
                    isSmall={isSmallBlock}
                    actions={{
                      setting: () => setActiveImage(image),
                      play: () => setActiveImage(image),
                      refresh: () => setActiveImage(image),
                    }}
                    nameBlock={name}
                  />
                </SwiperSlide>
              ))}
              {[...Array(countEmptyImages)].map((item, key) => (
                // eslint-disable-next-line react/no-array-index-key
                <SwiperSlide key={`${item}_${key}`}>
                  <ImageBlock
                    isSmall={isSmallBlock}
                    nameBlock={name}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={cx({ 
            [styles.footerBottom]: !isSmallBlock,
            [styles.footerTop]: isSmallBlock,
          })}
          >
            {!isSmallBlock && (
            <ButtonLinks
              links={links}
              className={styles.buttonLinks}
              isDisabledLinks={isDisabledLinks}
            />
            )}
            {/* <NavMenu /> */}
          </div>
        </motion.div>
        )}
      </AnimatePresence>
      <ModalWithInfo
        isOpen={activeImage !== null}
        onClose={() => setActiveImage(null)}
        title={`${titleModal} #${activeImage?.id}`}
        info={activeImage?.params?.length ? activeImage?.params : undefined}
        img={activeImage?.imageUrl}
        classNameInfoLabel={cx({
          [styles.infoLabelMultiPass]: name === 'MULTIPASSES', 
          [styles.infoLabelAvatar]: name === 'Avatars', 
          [styles.infoLabelCard]: name === 'A-ID CARDS', 
 
        })}
        classNameInfoValue={cx({
          [styles.infoValueMultiPass]: name === 'MULTIPASSES', 
          [styles.infoValueAvatar]: name === 'Avatars', 
          [styles.infoValueCard]: name === 'A-ID CARDS', 
 
        })}
        className={cx({ 
          [styles.center]: name === 'GEAR TOKENS',
          [styles.paddingBig]: name === 'MULTIPASSES', 
          [styles.paddingAvatar]: name === 'Avatars', 
        })}
        buttonsClassName={cx({
          [styles.buttonAvatar]: name === 'Avatars', 
        })}
      >
        <div className={cx(
          styles.actions, 
          classNameActions, 
          { 
            [styles.buttonsForGear]: name === 'GEAR TOKENS',
            [styles.paddingBig]: name === 'MULTIPASSES',  
            [styles.actionAvatar]: name === 'Avatars', 
          },
        )}
        >
          {actions?.map(({
            nameAction, imgAction, action, 
          }) => (
            <Button
              key={nameAction}
              onClick={() => action(activeImage?.id)}
              icon={imgAction}
              className={styles.buttonAction}
              classNameIcon={cx(
                styles.buttonActionIcon, 
                { [styles.buttonActionIconWindow]: isWindows },
              )}
              type={TypeButton.link}
              isDisabled={name === 'MULTIPASSES' && nameAction === 'CLAIM GEAR' ? activeImage?.isClaimedGear : false}
            >
              <p className={styles.button_label}>{nameAction}</p>
            </Button>
          ))}
        </div>
      </ModalWithInfo>
    </div>
  );
};
