import createReducer from 'utils/createReducer';
import { RequestStatus } from 'types';
import { LeaderState } from 'types/store/LeaderState';
import { LeaderActionTypes } from './actionsTypes';
import { leaderHandlers } from './handlers';

export const leaderInitialState: Readonly<LeaderState> = {
  leaderBoard: [],
  ui: {
    [LeaderActionTypes.GetData]: RequestStatus.INIT,
  },
};

export default createReducer(leaderInitialState, leaderHandlers);
