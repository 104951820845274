export default [{ inputs: [{ internalType: 'address', name: '_card', type: 'address' }, { internalType: 'address', name: '_awakenedToken', type: 'address' }, { internalType: 'uint16', name: '_maxSupply', type: 'uint16' }], stateMutability: 'nonpayable', type: 'constructor' }, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'owner', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'approved', type: 'address', 
  }, {
    indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256', 
  }],
  name: 'Approval',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'owner', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'operator', type: 'address', 
  }, {
    indexed: false, internalType: 'bool', name: 'approved', type: 'bool', 
  }],
  name: 'ApprovalForAll',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'blockId', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint256', name: 'cardId', type: 'uint256', 
  }],
  name: 'AssignCard',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'blockId', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint32', name: 'cityId', type: 'uint32', 
  }],
  name: 'AssignCity',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'purchaser', type: 'address', 
  }, {
    indexed: false, internalType: 'uint256', name: 'blockId', type: 'uint256', 
  }],
  name: 'BuyBlock',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'blockId', type: 'uint256', 
  }],
  name: 'MintWithCard',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'previousOwner', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'newOwner', type: 'address', 
  }],
  name: 'OwnershipTransferred',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'blockId', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint256', name: 'cardId', type: 'uint256', 
  }],
  name: 'RemoveCard',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'awakened', type: 'address', 
  }],
  name: 'SetAwakenedToken',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'string', name: 'basesURI', type: 'string', 
  }],
  name: 'SetBaseURI',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'card', type: 'address', 
  }],
  name: 'SetCitizenCard',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'blockId', type: 'uint256', 
  }, {
    indexed: false, internalType: 'string', name: 'tokenURI', type: 'string', 
  }],
  name: 'SetTokenURI',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'from', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'to', type: 'address', 
  }, {
    indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256', 
  }],
  name: 'Transfer',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256', 
  }],
  name: 'WithdrawEth',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'blockId', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256', 
  }],
  name: 'WithdrawRewards',
  type: 'event', 
}, {
  inputs: [{ internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'tokenId', type: 'uint256' }], name: 'approve', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'blockId', type: 'uint256' }, { internalType: 'uint256', name: 'cardId', type: 'uint256' }], name: 'assignCard', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'blockId', type: 'uint256' }, { internalType: 'uint32', name: 'cityId', type: 'uint32' }], name: 'assignCity', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [], name: 'awakenedToken', outputs: [{ internalType: 'contract IAwakenedToken', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'owner', type: 'address' }], name: 'balanceOf', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'blockId', type: 'uint256' }], name: 'buyBlock', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint32', name: 'cityId', type: 'uint32' }], name: 'canAssignCity', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'citizenCard', outputs: [{ internalType: 'contract ICitizenCard', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }], name: 'getApproved', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'blockId', type: 'uint256' }], name: 'getBlockInfo', outputs: [{ internalType: 'uint32', name: 'cityId', type: 'uint32' }, { internalType: 'uint256', name: 'cardId', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'getBlockPrice', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }], name: 'getRewards', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'blockId', type: 'uint256' }], name: 'getRewardsPaid', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'owner', type: 'address' }, { internalType: 'address', name: 'operator', type: 'address' }], name: 'isApprovedForAll', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'cardId', type: 'uint256' }], name: 'isCardUsed', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'maxSupply', outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint32', name: 'cityId', type: 'uint32' }], name: 'mint', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'cardId', type: 'uint256' }], name: 'mintWithCard', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [], name: 'name', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }], name: 'ownerOf', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'blockId', type: 'uint256' }, { internalType: 'uint256', name: 'cardId', type: 'uint256' }], name: 'removeCard', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'from', type: 'address' }, { internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'tokenId', type: 'uint256' }], name: 'safeTransferFrom', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'from', type: 'address' }, { internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'tokenId', type: 'uint256' }, { internalType: 'bytes', name: '_data', type: 'bytes' }], name: 'safeTransferFrom', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'operator', type: 'address' }, { internalType: 'bool', name: 'approved', type: 'bool' }], name: 'setApprovalForAll', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: '_awakenedToken', type: 'address' }], name: 'setAwakenedToken', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'string', name: 'basesURI', type: 'string' }], name: 'setBaseURI', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: '_card', type: 'address' }], name: 'setCitizenCard', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }, { internalType: 'string', name: '_tokenURI', type: 'string' }], name: 'setTokenURI', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }], name: 'supportsInterface', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'symbol', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'index', type: 'uint256' }], name: 'tokenByIndex', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'owner', type: 'address' }, { internalType: 'uint256', name: 'index', type: 'uint256' }], name: 'tokenOfOwnerByIndex', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }], name: 'tokenURI', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'totalSupply', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'from', type: 'address' }, { internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'tokenId', type: 'uint256' }], name: 'transferFrom', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }], name: 'transferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [], name: 'withdrawAllRewards', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address payable', name: 'member', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'withdrawETH', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'blockId', type: 'uint256' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'withdrawRewards', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}];
