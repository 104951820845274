import React, { FC, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IconCaretImg } from 'assets/img';
import cx from 'classnames';
import styles from './styles.module.css';

interface WelcomeProps {
  className?: string,
  title: string,
  description: string,
  subTitle?: string,
}

export const Welcome: FC<WelcomeProps> = ({ 
  className = '', title, subTitle, description,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={cx(styles.welcome, className)}>
      <div className={styles.header}>
        <span className={styles.title}>
          {title}
          {' '}
          {subTitle && (<span className={styles.titleUniverse}>{subTitle}</span>)}
        </span>
        <button
          onClick={handleOpen}
          className={styles.button}
        >
          <motion.img
            src={IconCaretImg}
            alt="caret"
            className={styles.icon}
            initial={false}
            animate={{ rotate: isOpen ? 180 : 360 }}
            transition={{ duration: 0.5, type: 'spring' }}
            whileHover={{ scale: 1.2, opacity: 0.5 }}
          />
        </button>
      </div>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="modalWelcome"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className={styles.bodyWelcome}
          >
            <span className={styles.text}>
              {description}
            </span>
            {/* <span className={styles.textReadMore}>Read more</span> */}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
