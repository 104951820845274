import { takeLeading } from 'redux-saga/effects';
import { GearsActionTypes } from '../actionsTypes';
import { gearsGetDataSaga } from './getData';
import { gearsApplyToAvatarSaga } from './applyToAvatar';
import { gearsClaimSaga } from './claim';

export default function* gearsSaga() {
  yield takeLeading(GearsActionTypes.GetData, gearsGetDataSaga);
  yield takeLeading(GearsActionTypes.ApplyToAvatar, gearsApplyToAvatarSaga);
  yield takeLeading(GearsActionTypes.Claim, gearsClaimSaga);
}
