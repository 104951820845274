import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus,
} from 'types';
import { mintGearsApi } from 'api';
import { 
  gearsSetStatus, gearsClaim,
} from 'store/gears/actionCreators';

export function* gearsClaimSaga({ type, payload }: ReturnType<typeof gearsClaim>) {
  try{
    yield put(gearsSetStatus({ type, status: RequestStatus.REQUEST }));

    yield call(mintGearsApi, payload.multiPassIds);

    if(payload.callback) payload.callback(); 
    
    yield put(gearsSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(gearsSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
