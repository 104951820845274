import { useSelector, shallowEqual } from 'react-redux';
import { authSelectors } from 'store/auth/selectors';
import { AuthActionTypes } from 'store/auth/actionsTypes';
import { MetamaskActionTypes } from 'store/metamask/actionTypes';
import { metamaskSelectors } from 'store/metamask/selectors';
import { MetamaskStatus, RequestStatus } from 'types';

export const useAuth = () => {
  const accessToken = useSelector(authSelectors.getProp('accessToken'));
  const addressWallet = useSelector(authSelectors.getProp('addressWallet'));

  const statusLogin = useSelector(authSelectors.getStatus(AuthActionTypes.Login));
  const statusConnect = useSelector(
    metamaskSelectors.getStatus(MetamaskActionTypes.Connect),
  );

  const {
    status,
    address,
  } = useSelector(metamaskSelectors.getState, shallowEqual);

  const isWalletConnected = status === MetamaskStatus.CONNECTED && address !== '';

  return {
    isUserAuthenticated: accessToken !== undefined 
      && address.toLocaleLowerCase() === addressWallet?.toLocaleLowerCase()
      && isWalletConnected,
    isLoading: statusConnect === RequestStatus.REQUEST || statusLogin === RequestStatus.REQUEST, 
    addressWallet,
  };
};
