import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.css';

interface ParameterItemProps {
  name: string,
  value: string,
  className?: string,
  isLastParam?: boolean,
}

export const ParameterItem: FC<ParameterItemProps> = ({
  name, value, className = '', isLastParam,
}) => (
  <div className={cx(styles.parameterItem, { [styles.lastParam]: isLastParam }, className)}>
    <span className={styles.paramName}>
      {name}
    </span>
    <span className={cx(styles.paramValue, { [styles.lastParamValue]: isLastParam })}>
      {value}
    </span>
  </div>
);
