import { RequestStatus } from 'types';
import { CitizenCardState } from 'types/store/CitizenCardState';
import { CitizenCardActionTypes } from './actionsTypes';

export const citizenCardSetState = (payload: Partial<CitizenCardState>) => ({
  type: CitizenCardActionTypes.SetState,
  payload,
});

export const citizenCardSetStatus = (
  payload: { type: CitizenCardActionTypes, status: RequestStatus },
) => ({
  type: CitizenCardActionTypes.SetStatus,
  payload,
});

export const citizenCardGetData = () => ({
  type: CitizenCardActionTypes.GetData,
});

export const citizenCardMint = (payload: { avatarIds: number[] }) => ({
  type: CitizenCardActionTypes.Mint,
  payload,
});

export const citizenCardWithdrawRewards = (payload: { 
  cardId: number, callback?: () => void,
}) => ({
  type: CitizenCardActionTypes.WithdrawRewards,
  payload,
});

export const citizenCardUpgrade = (payload: { 
  cardIds: number[], multiPassIds: number[], callback?: () => void
}) => ({
  type: CitizenCardActionTypes.Upgrade,
  payload,
});
