import { ActionFn } from 'types/redux';
import { RewardsState } from 'types/store/RewardsState';
import { rewardsSetState, rewardsSetStatus } from './actionCreators';
import { RewardsActionTypes } from './actionsTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RewardsHandlerFn<F extends (...args : any) => any> = ActionFn<RewardsState, ReturnType<F>>;

const setState: RewardsHandlerFn<typeof rewardsSetState> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: RewardsHandlerFn<typeof rewardsSetStatus> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const rewardsHandlers = {
  [RewardsActionTypes.SetState]: setState,
  [RewardsActionTypes.SetStatus]: setStatus,
};
