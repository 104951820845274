export default [{ inputs: [{ internalType: 'address', name: '_operator', type: 'address' }, { internalType: 'address', name: '_multipass', type: 'address' }, { internalType: 'address', name: '_avatar', type: 'address' }, { internalType: 'address', name: '_awakaned', type: 'address' }, { internalType: 'uint16', name: '_maxSupply', type: 'uint16' }], stateMutability: 'nonpayable', type: 'constructor' }, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'owner', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'approved', type: 'address', 
  }, {
    indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256', 
  }],
  name: 'Approval',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'owner', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'operator', type: 'address', 
  }, {
    indexed: false, internalType: 'bool', name: 'approved', type: 'bool', 
  }],
  name: 'ApprovalForAll',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'cardId', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint32', name: 'cityId', type: 'uint32', 
  }],
  name: 'AssignCity',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint32', name: 'cardId', type: 'uint32', 
  }, {
    indexed: false, internalType: 'address', name: 'account', type: 'address', 
  }, {
    indexed: false, internalType: 'uint8', name: 'oldLevel', type: 'uint8', 
  }, {
    indexed: false, internalType: 'uint8', name: 'burnedMultipassLevel', type: 'uint8', 
  }],
  name: 'CardUpgrade',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'cardId', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint256', name: 'avatarId', type: 'uint256', 
  }],
  name: 'MintWithAvatar',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'cardId', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint256', name: 'multipassId', type: 'uint256', 
  }],
  name: 'MintWithMutiPass',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'previousOwner', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'newOwner', type: 'address', 
  }],
  name: 'OwnershipTransferred',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'avatar', type: 'address', 
  }],
  name: 'SetAvatar',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'awakened', type: 'address', 
  }],
  name: 'SetAwakenedToken',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'string', name: 'basesURI', type: 'string', 
  }],
  name: 'SetBaseURI',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'multipass', type: 'address', 
  }],
  name: 'SetMultiPass',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'cardId', type: 'uint256', 
  }, {
    indexed: false, internalType: 'string', name: 'tokenURI', type: 'string', 
  }],
  name: 'SetTokenURI',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'from', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'to', type: 'address', 
  }, {
    indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256', 
  }],
  name: 'Transfer',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256', 
  }],
  name: 'WithdrawEth',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'cardId', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256', 
  }],
  name: 'WithdrawRewards',
  type: 'event', 
}, { stateMutability: 'payable', type: 'fallback' }, {
  inputs: [], name: 'LEVELCAP', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'tokenId', type: 'uint256' }], name: 'approve', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'cardId', type: 'uint256' }, { internalType: 'uint32', name: 'cityId', type: 'uint32' }], name: 'assignCity', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [], name: 'avatar', outputs: [{ internalType: 'contract IAvatar', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'awakenedToken', outputs: [{ internalType: 'contract IAwakenedToken', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'owner', type: 'address' }], name: 'balanceOf', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256[]', name: '_avatarIds', type: 'uint256[]' }, { internalType: 'uint256[]', name: 'timestamps', type: 'uint256[]' }, { internalType: 'uint8', name: 'v', type: 'uint8' }, { internalType: 'bytes32', name: 'r', type: 'bytes32' }, { internalType: 'bytes32', name: 's', type: 'bytes32' }], name: 'batchMintWithAvatar', outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }], name: 'getApproved', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256[]', name: 'timestamps', type: 'uint256[]' }, { internalType: 'uint256', name: 'avatarCount', type: 'uint256' }, { internalType: 'uint256', name: 'offset', type: 'uint256' }], name: 'getAvatarReward', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'cardId', type: 'uint256' }], name: 'getCardInfo', outputs: [{ internalType: 'uint8', name: 'level', type: 'uint8' }, { internalType: 'uint32', name: 'cityId', type: 'uint32' }, { internalType: 'uint32', name: 'multipassId', type: 'uint32' }, { internalType: 'uint32', name: 'avatarId', type: 'uint32' }, { internalType: 'uint32', name: 'gearId', type: 'uint32' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'cardId', type: 'uint256' }], name: 'getRewards', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'cardId', type: 'uint256' }], name: 'getRewardsPaid', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'owner', type: 'address' }, { internalType: 'address', name: 'operator', type: 'address' }], name: 'isApprovedForAll', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'maxSupply', outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint8', name: 'level', type: 'uint8' }, { internalType: 'string', name: 'tokenURI_', type: 'string' }], name: 'mint', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: '_avatarId', type: 'uint256' }, { internalType: 'uint256[]', name: 'timestamps', type: 'uint256[]' }, { internalType: 'uint8', name: 'v', type: 'uint8' }, { internalType: 'bytes32', name: 'r', type: 'bytes32' }, { internalType: 'bytes32', name: 's', type: 'bytes32' }], name: 'mintWithAvatar', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint16', name: '_multipassId', type: 'uint16' }], name: 'mintWithMultiPass', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [], name: 'multipass', outputs: [{ internalType: 'contract IMultipass', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'name', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'owner', type: 'address' }], name: 'nonces', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }], name: 'ownerOf', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'from', type: 'address' }, { internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'tokenId', type: 'uint256' }], name: 'safeTransferFrom', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'from', type: 'address' }, { internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'tokenId', type: 'uint256' }, { internalType: 'bytes', name: '_data', type: 'bytes' }], name: 'safeTransferFrom', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'operator', type: 'address' }, { internalType: 'bool', name: 'approved', type: 'bool' }], name: 'setApprovalForAll', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: '_avatar', type: 'address' }], name: 'setAvatar', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: '_awakaned', type: 'address' }], name: 'setAwakanedToken', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'string', name: 'basesURI', type: 'string' }], name: 'setBaseURI', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: '_multipass', type: 'address' }], name: 'setMultiPass', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'cardId', type: 'uint256' }, { internalType: 'string', name: 'cardURI', type: 'string' }], name: 'setTokenURI', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }], name: 'supportsInterface', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'symbol', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'index', type: 'uint256' }], name: 'tokenByIndex', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'owner', type: 'address' }, { internalType: 'uint256', name: 'index', type: 'uint256' }], name: 'tokenOfOwnerByIndex', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }], name: 'tokenURI', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'totalSupply', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'from', type: 'address' }, { internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'tokenId', type: 'uint256' }], name: 'transferFrom', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }], name: 'transferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint32[]', name: 'cardIds', type: 'uint32[]' }, { internalType: 'uint16[]', name: 'multipassIds', type: 'uint16[]' }], name: 'upgradeCard', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [], name: 'withdrawAllRewards', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address payable', name: 'member', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'withdrawETH', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'cardId', type: 'uint256' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'withdrawRewards', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, { stateMutability: 'payable', type: 'receive' }];
