import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'types';
import { ChooseItemsModal } from 'components';
import { IconSettingImg } from 'assets/img';
import { blockAssignCard } from 'store/block/actionCreators';
import { BlockActionTypes } from 'store/block/actionsTypes';
import { blockSelectors } from 'store/block/selectors';
import { citizenCardSelectors } from 'store/citizenCard/selectors';

export const useAssignBlock = () => {
  const dispatch = useDispatch();
  
  const statusAssignCard = useSelector(blockSelectors.getStatus(BlockActionTypes.AssignCard));

  const blocksMetas = useSelector(blockSelectors.getProp('blocksMetas'));
  const blocks = useMemo(() => blocksMetas.map((item) => ({
    id: item.id,
    label: `// ID ${item.id}`,
    value: `// Lvl ${item.level}`,
    img: item.imageUrl,
  })), [blocksMetas]); 

  const citizenCardMetas = useSelector(citizenCardSelectors.getProp('citizenCardMetas'));

  const citizenCard = useMemo(() => citizenCardMetas.map((item) => ({
    id: item.id,
    label: `// ID ${item.id}`,
    value: `// Lvl ${item.level}`,
    img: item.imageUrl,
  })), [citizenCardMetas]);

  const [citizenCardIds, setCitizenCardIds] = useState<number[]>([]);
  const [blockIds, setBlockIds] = useState<number[]>([]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [nextStepModal, setNextStepModal] = useState<boolean>(false);
  
  const nextStep = useCallback((blockIdsNew: number[]) => {
    if(blockIdsNew.length > 0) {
      setBlockIds(blockIdsNew);
      setOpenModal(false); 
      setNextStepModal(true);
    }
  }, []);

  const handleFistStepOpen = useCallback((blockId?: number) => {
    setOpenModal(true); 
    if (blockId) setBlockIds([blockId]);
  }, []);

  const handleFistStepClose = useCallback(() => {
    setOpenModal(false); 
    setBlockIds([]);
  }, []);

  const handleCloseAll = useCallback(() => {
    setOpenModal(false); 
    setNextStepModal(false); 
    setCitizenCardIds([]);
    setBlockIds([]);
  }, []);

  const handleAssignCard = useCallback((citizenCardIdsNew: number[]) => {
    if(blockIds.length === citizenCardIdsNew.length) {
      dispatch(blockAssignCard({
        cardId: citizenCardIdsNew[0],
        blockId: blockIds[0], 
        callback: handleCloseAll,
      }));
    }
  }, [dispatch, blockIds, handleCloseAll]);

  return {
    openAssignBlock: handleFistStepOpen,
    modalAssignBlock: [
      <ChooseItemsModal
        key="useApplyGears-Next step"
        buttonLabel="Next step"
        items={blocks}
        label="Select the Block you want to assign below."
        title="ASSIGN BLOCK TOKEN"
        isOpen={openModal}
        onClose={handleFistStepClose}
        onButtonClick={nextStep}
        onChoose={setBlockIds}
        buttonIsDisabled={blockIds.length === 0}
      />,
      <ChooseItemsModal
        key="useApplyGears-ASSIGN BLOCK"
        buttonLabel="ASSIGN BLOCK"
        buttonImg={IconSettingImg}
        items={citizenCard}
        label={`Select the Card to which you would 
        like to assign a Block Token.
        
        You need a level 2 Block or above to complete this action.
        `}
        title="ASSIGN BLOCK TOKEN"
        isOpen={nextStepModal}
        onClose={handleCloseAll}
        onButtonClick={handleAssignCard}
        onChoose={setCitizenCardIds}
        buttonIsDisabled={citizenCardIds.length !== blockIds.length}
        buttonIsLoading={statusAssignCard === RequestStatus.REQUEST}
      />,
    ],
  };
};
