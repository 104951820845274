import React, { FC, useEffect, useMemo } from 'react';
import { NftList, Welcome } from 'components';
import { ClaimTokens } from 'containers';
import { useDispatch, useSelector } from 'react-redux';
import {
  useUpdateAvatar, useClaimGears, useMintAvatar, 
  useAuth,
} from 'hooks';
import {
  SwapImg, SettingsImg, ArrowImg, 
} from 'assets/img';
import { multiPassGetData } from 'store/multiPass/actionCreators';
import { avatarGetData } from 'store/avatar/actionCreators';
import { gearsGetData } from 'store/gears/actionCreators';
import { multiPassSelectors } from 'store/multiPass/selectors';
import { linksOpensea, linksOpenseaForItem } from 'appConstants';

export const MultiPass: FC = () => {
  const dispatch = useDispatch();
  
  const { modalUpdateAvatar, openUpdateAvatar } = useUpdateAvatar();
  const { modalMintAvatar, openMintAvatar } = useMintAvatar();
  const { isUserAuthenticated, addressWallet } = useAuth();
  const { modalClaimGears, openClaimGears } = useClaimGears();

  const multiPassMetas = useSelector(multiPassSelectors.getProp('multiPassMetas'));

  useEffect(() => {
    if (isUserAuthenticated) {
      dispatch(multiPassGetData());
      dispatch(avatarGetData());
      dispatch(gearsGetData());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUserAuthenticated, addressWallet]);

  const linksMultiPass = useMemo(() => [
    { name: 'Mint An Avatar', method: () => openMintAvatar() },
    {
      name: 'Sell Multipasses',
      method: () =>
        window.open(
          `${linksOpensea.MultiPass}`, 
          '_blank',
        ), 
    },
  ], [openMintAvatar]);

  const actionsMultiPass = useMemo(() => [
    { nameAction: 'MINT AVATAR', imgAction: SwapImg, action: (id?: number) => openMintAvatar(id) },
    { nameAction: 'UPGRADE AVATAR', imgAction: SwapImg, action: (id?: number) => openUpdateAvatar({ multiPassId: id }) },
    { nameAction: 'CLAIM GEAR', imgAction: SettingsImg, action: (id?: number) => openClaimGears(id) },
    { 
      nameAction: 'SELL MULTIPASS', 
      imgAction: ArrowImg, 
      action: (id?: number) => window.open(
        `${linksOpenseaForItem.MultiPass}/${id}`, 
        '_blank',
      ),
    },
  ], [openClaimGears, openMintAvatar, openUpdateAvatar]);

  return (
    <>
      <Welcome 
        title="MULTIPASSES" 
        description={`
        Enter the Awakened Universe with the MultiPass ERC-1155 Tokens. 
        Hold and watch as it automatically upgrades over time, unlocking the potential to mint rarer Avatars. Claim a Gear Token and customize your Avatar's weapons, and even buy real estate in your home city. 
        Each decision you make with your MultiPass creates a truly unique experience.
      `}
      />
      <ClaimTokens />
      <NftList
        key="MULTIPASSES"
        name="MULTIPASSES"
        titleModal="MULTIPASS"
        isDisabledLinks={!isUserAuthenticated || !multiPassMetas.length}
        links={linksMultiPass} 
        images={multiPassMetas}
        actions={actionsMultiPass}
      />
      {modalMintAvatar}
      {modalUpdateAvatar}
      {modalClaimGears}
    </>
  );
};
