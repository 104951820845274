import React, { 
  FC, useCallback, useState, useMemo, useEffect,
} from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { 
  ChooseTokenList, Button, TypeButton, Welcome,
} from 'components';
import { contracts } from 'appConstants';
import { useWindowSize, useAuth } from 'hooks';
import { RequestStatus } from 'types';
import { avatarGetData, avatarApprove } from 'store/avatar/actionCreators';
import { citizenCardMint } from 'store/citizenCard/actionCreators';
import { citizenCardSelectors } from 'store/citizenCard/selectors';
import { avatarSelectors } from 'store/avatar/selectors';
import { CitizenCardActionTypes } from 'store/citizenCard/actionsTypes';
import { AvatarActionTypes } from 'store/avatar/actionsTypes';
import styles from './styles.module.css';

type ConvAvatarToCitizenProps = {
  className?: string,
};

export type ChooseToken = {
  label: string,
  value: string,
  img: string,
  id: number,
};

function getAdaptCountPerPage(width: number | undefined): number {
  if (width && width > 1200) return 4;
  if (width && width > 550 && width < 1200) return 3; 
  if (width && width < 550) return 2; 
  return 4;
}

export const ConvAvatarToCitizen: FC<ConvAvatarToCitizenProps> = ({ className = '' }) => {
  const dispatch = useDispatch();
  
  const { isUserAuthenticated } = useAuth();
  const [selectedAvatars, setSelectedAvatars] = useState<ChooseToken[]>([]);

  const statusMint = useSelector(citizenCardSelectors.getStatus(CitizenCardActionTypes.Mint));
  const statusApprove = useSelector(avatarSelectors.getStatus(AvatarActionTypes.Approve));

  const avatarsMetas = useSelector(avatarSelectors.getProp('avatarsMetas'));
  const isApproveForCitizenCard = useSelector(avatarSelectors.getProp('isApproveForCitizenCard'));

  const avatars = useMemo(() => avatarsMetas.map((item) => ({
    id: item.id,
    label: `// ID ${item.id}`,
    value: '// Lvl 30',
    img: item.imageUrl,
  })), [avatarsMetas]);

  const { width } = useWindowSize();
  const countOnPage = getAdaptCountPerPage(width);

  const handleChoose = useCallback((newAvatars: ChooseToken[]) => {
    setSelectedAvatars(newAvatars);
  }, []);

  const handleSubmit = useCallback(() => {
    const avatarIds = selectedAvatars.map(({ id }) => id);
    dispatch(citizenCardMint({ avatarIds }));
  }, [dispatch, selectedAvatars]);

  const handleApprove = useCallback(() => {
    dispatch(avatarApprove({ address: contracts.CitizenCard }));
  }, [dispatch]);

  useEffect(() => {
    if (isUserAuthenticated) {
      dispatch(avatarGetData());
    }
  }, [dispatch, isUserAuthenticated]);

  return (
    <section className={cx(styles.convAvatarToCitizen, className)}>
      <Welcome 
        title="CONVERT AVATAR" 
        description={`
        The Exit Containment module is the final step to unleashing the full potential of your Avatar.
        After undergoing Metacorp's groundbreaking enhancement surgery, your Avatar is held in a secure containment area until they are fully stabilized and ready to be released back into the general population.
        Once your Avatar has been released from containment, you can proudly showcase their new upgrades with your A-ID card, and take on any challenge that comes your way in the Awakened Universe.        
      `}
      />
      <div className={styles.containerAvatar}>
        <span className={styles.titleAvatar}>AVATARS</span>
        <span className={styles.subTitleAvatar}>Click to make your selections.</span>
        <ChooseTokenList
          items={avatars}
          countOnPage={countOnPage}
          onChoose={handleChoose}
        />
      </div>
      <div className={styles.imageCenter} />
      <div className={styles.containerCitizenCard}>
        <span className={styles.titleAvatar}>CITIZEN CARDS</span>
        <span className={styles.subTitleAvatar}>What you will get.</span>
        <div className={styles.containerImages}>
          <ChooseTokenList
            items={selectedAvatars}
            countOnPage={countOnPage}
            isDisabledSelect={false}
          />
        </div>
      </div>
      <div className={styles.containerSubmit}>
        <div className={styles.buttonConvert}>
          {!isApproveForCitizenCard && (
            <Button
              onClick={handleApprove}
              type={TypeButton.red}
              className={styles.buttonConvert}
              isLoading={statusApprove === RequestStatus.REQUEST}
              isDisabled={selectedAvatars.length === 0 || !isUserAuthenticated}
            >
              Approve
            </Button>
          )}
          {isApproveForCitizenCard && (
            <Button
              onClick={handleSubmit}
              type={TypeButton.red}
              className={styles.buttonConvert}
              isLoading={statusMint === RequestStatus.REQUEST}
              isDisabled={
                !isApproveForCitizenCard 
                || selectedAvatars.length === 0 
                || !isUserAuthenticated
              }
            >
              CONVERT NOW
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};
