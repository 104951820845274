import React, { FC } from 'react';
import { Welcome } from 'components';

export const Market: FC = () => (
  <Welcome
    title="Marketplace" 
    subTitle="COMING SOON"
    description={`
    The Awakened Marketplace will soon become the go-to destination for all your mission-critical needs.
    Whether you're a seasoned professional or just starting out, you'll need a few tools of the trade in the fight against the Variants. 
    Here, you'll find an impressive selection of weapons, vehicles, and other state-of-the-art equipment that will give you the edge you need to complete your objectives.
    `}
  />
);
