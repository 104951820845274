import { RequestStatus } from 'types';
import { MultiPassState } from 'types/store/MultiPassState';
import { MultiPassActionTypes } from './actionsTypes';

export const multiPassSetState = (payload: Partial<MultiPassState>) => ({
  type: MultiPassActionTypes.SetState,
  payload,
});

export const multiPassSetStatus = (
  payload: { type: MultiPassActionTypes, status: RequestStatus },
) => ({
  type: MultiPassActionTypes.SetStatus,
  payload,
});

export const multiPassGetData = () => ({
  type: MultiPassActionTypes.GetData,
});

export const multiPassApprove = (payload: { address: string }) => ({
  type: MultiPassActionTypes.Approve,
  payload,
});
