import React, { FC, useEffect, useMemo } from 'react';
import { NftList, Welcome } from 'components';
import { ClaimTokens } from 'containers';
import { useDispatch, useSelector } from 'react-redux';
import {
  useAuth, useAssignBlock,
} from 'hooks';
import {
  SwapImg, 
} from 'assets/img';
import { citizenCardGetData } from 'store/citizenCard/actionCreators';
import { blockGetData } from 'store/block/actionCreators';
import { blockSelectors } from 'store/block/selectors';
import { linksOpensea } from 'appConstants';

export const Blocks: FC = () => {
  const dispatch = useDispatch();
  
  const { isUserAuthenticated, addressWallet } = useAuth();
  const blocksMetas = useSelector(blockSelectors.getProp('blocksMetas'));
  const { modalAssignBlock, openAssignBlock } = useAssignBlock();

  useEffect(() => {
    if (isUserAuthenticated) {
      dispatch(blockGetData());
      dispatch(citizenCardGetData());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUserAuthenticated, addressWallet]);
  
  const linksBlocks = useMemo(() => [
    { name: 'Assign to ID', method: openAssignBlock },
    { name: 'Sell Blocks', method: () => window.open(`${linksOpensea.Block}`, '_blank') },
  ], [openAssignBlock]);

  const actionsBlocks = useMemo(() => [
    { nameAction: 'Assign block', imgAction: SwapImg, action: (id?: number) => openAssignBlock(id) },
  ], [openAssignBlock]);

  return (
    <>
      <Welcome 
        title="BLOCKS" 
        description={`
      Enter the exciting world of the Awakened Universe and start building your virtual empire today.
      With the Blocks module, you can easily claim, sell, assign and manage your properties, whether you're seeking prime commercial spaces or peaceful residential enclaves. 
      Don't miss out on this opportunity to become a part of the elite group of property owners in the Awakened Universe.
      `}
      />
      <ClaimTokens />
      <NftList
        key="Blocks"
        name="Blocks"
        titleModal="Block"
        isDisabledLinks={!isUserAuthenticated || !blocksMetas.length}
        links={linksBlocks} 
        actions={actionsBlocks}
        images={blocksMetas}
      />
      {modalAssignBlock}
    </>
  );
};
