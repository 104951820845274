import React, { FC } from 'react';
import RModal from 'react-modal';
import {
  CloseImg,
} from 'assets/img';
import cx from 'classnames';
import { ButtonIcon } from 'components/ButtonIcon';
import styles from './styles.module.css';

interface ModalProps {
  className?: string
  isOpen: boolean
  onClose?: () => void
  title?: string
  children?: React.ReactNode
}

export const Modal: FC<ModalProps> = ({
  children,
  className,
  onClose,
  isOpen,
  title = '',
}) => (
  <RModal
    onRequestClose={onClose}
    shouldCloseOnOverlayClick={false}
    ariaHideApp={false}
    overlayClassName={styles.overlay}
    className={cx(styles.modal_body, className)}
    isOpen={isOpen}
  >
    <div className={styles.header}>
      <p className={styles.header_label}>{title}</p>

      <div className={styles.close}>
        <ButtonIcon
          onClick={onClose}
          imageURL={CloseImg}
        />
      </div>
    </div>
    <div className={styles.body}>
      {children}
    </div>
  </RModal>
);
