import createReducer from 'utils/createReducer';
import { RequestStatus } from 'types';
import { AvatarState } from 'types/store/AvatarState';
import { AvatarActionTypes } from './actionsTypes';
import { avatarHandlers } from './handlers';

export const avatarInitialState: Readonly<AvatarState> = {
  avatarsMetas: [],
  isApproveForCitizenCard: false,
  isApproveForGears: false,
  ui: {
    [AvatarActionTypes.GetData]: RequestStatus.INIT,
    [AvatarActionTypes.Mint]: RequestStatus.INIT,
    [AvatarActionTypes.Update]: RequestStatus.INIT,
    [AvatarActionTypes.Approve]: RequestStatus.INIT,
  },
};

export default createReducer(avatarInitialState, avatarHandlers);
