import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus,
} from 'types';
import { mintAvatarApi } from 'api';
import { 
  avatarSetStatus, avatarMint, avatarGetData,
} from 'store/avatar/actionCreators';
import { multiPassGetData } from 'store/multiPass/actionCreators';

export function* avatarMintSaga({ type, payload }: ReturnType<typeof avatarMint>) {
  try{
    yield put(avatarSetStatus({ type, status: RequestStatus.REQUEST }));

    yield call(mintAvatarApi, payload.multiPassIds);
    yield put(multiPassGetData());
    yield put(avatarGetData());

    if(payload.callback) payload.callback();
    yield put(avatarGetData());
    
    yield put(avatarSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(avatarSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
