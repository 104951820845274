import React, { FC, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NftList } from 'components';
import {
  useUpdateAvatar, useApplyGears, useClaimGears, useMintAvatar, 
  useWindowSize, useAuth, useAssignBlock, useClaimCard, useUpdateCard,
  useRemoveBlock,
} from 'hooks';
import { routes, linksOpensea, linksOpenseaForItem } from 'appConstants';
import {
  SwapImg, SettingsImg, ArrowImg, 
} from 'assets/img';
import { multiPassGetData } from 'store/multiPass/actionCreators';
import { avatarGetData } from 'store/avatar/actionCreators';
import { blockGetData } from 'store/block/actionCreators';
import { gearsGetData } from 'store/gears/actionCreators';
import { citizenCardGetData } from 'store/citizenCard/actionCreators';
import { citizenCardSelectors } from 'store/citizenCard/selectors';
import { multiPassSelectors } from 'store/multiPass/selectors';
import { blockSelectors } from 'store/block/selectors';
import { gearsSelectors } from 'store/gears/selectors';
import { avatarSelectors } from 'store/avatar/selectors';

import styles from './styles.module.css';

type BlocksProps = {
  className?: string,
};

function getAdaptIsSmall(width: number | undefined): boolean {
  if (width && width > 1200) return true;
  if (width && width > 550 && width < 1200) return false; 
  return true;
}

export const NftGrid: FC<BlocksProps> = ({ className = '' }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isUserAuthenticated, addressWallet } = useAuth();
  const { modalUpdateAvatar, openUpdateAvatar } = useUpdateAvatar();
  const { modalApplyGears, openApplyGears } = useApplyGears();
  const { modalClaimGears, openClaimGears } = useClaimGears();
  const { modalMintAvatar, openMintAvatar } = useMintAvatar();
  const { modalAssignBlock, openAssignBlock } = useAssignBlock();
  const { modalClaimCard, openClaimCard } = useClaimCard();
  const { modalUpgradeCards, openUpgradeCards } = useUpdateCard();
  const { modalRemoveBlock, openRemoveBlock } = useRemoveBlock();

  const { width } = useWindowSize();
  const isSmallBlock = useMemo(() => getAdaptIsSmall(width), [width]);

  const multiPassMetas = useSelector(multiPassSelectors.getProp('multiPassMetas'));
  const blocksMetas = useSelector(blockSelectors.getProp('blocksMetas'));
  const avatarsMetas = useSelector(avatarSelectors.getProp('avatarsMetas'));
  const citizenCardMetas = useSelector(citizenCardSelectors.getProp('citizenCardMetas'));
  const gearsMetas = useSelector(gearsSelectors.getProp('gearsMetas'));

  useEffect(() => {
    if (isUserAuthenticated) {
      dispatch(gearsGetData());
      dispatch(multiPassGetData());
      dispatch(avatarGetData());
      dispatch(blockGetData());
      dispatch(citizenCardGetData());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUserAuthenticated, addressWallet]);

  const linksAvatars = useMemo(() => [
    { name: 'Mint Avatar', method: () => openMintAvatar() },
    { name: 'Upgrade Avatar', method: () => openUpdateAvatar() },
    { name: 'Claim Gear', method: () => openClaimGears() },
  ], [openClaimGears, openMintAvatar, openUpdateAvatar]);

  const actionsAvatars = useMemo(() => [
    { nameAction: 'Upgrade', imgAction: SwapImg, action: (id?: number) => openUpdateAvatar({ avatarId: id }) },
    { nameAction: 'APPLY GEAR', imgAction: SettingsImg, action: (id?: number) => openApplyGears({ avatarId: id }) },
    { nameAction: 'SELL AVATAR', imgAction: ArrowImg, action: (id?: number) => window.open(`${linksOpenseaForItem.Avatar}/${id}`, '_blank') },
    {
      nameAction: 'Exit containment',
      imgAction: ArrowImg,
      action: () => {
        navigate(routes.convert.root); 
        window.scrollTo(0, 0);
      }, 
    }, 
  ], [navigate, openApplyGears, openUpdateAvatar]);

  const linksBlocks = useMemo(() => [
    { name: 'Assign to ID', method: openAssignBlock },
    { name: 'Remove to ID', method: openRemoveBlock },
    { name: 'Sell Blocks', method: () => window.open(`${linksOpensea.Block}`, '_blank') },
  ], [openAssignBlock, openRemoveBlock]);

  const actionsBlocks = useMemo(() => [
    { nameAction: 'Assign block', imgAction: SwapImg, action: (id?: number) => openAssignBlock(id) },
  ], [openAssignBlock]);

  const linksMultiPass = useMemo(() => [
    { name: 'Mint Avatar', method: () => openMintAvatar() },
    { name: 'Claim Gear', method: () => openClaimGears() },
  ], [openClaimGears, openMintAvatar]);

  const actionsMultiPass = useMemo(() => [
    { nameAction: 'MINT AVATAR', imgAction: SwapImg, action: (id?: number) => openMintAvatar(id) },
    { nameAction: 'UPGRADE AVATAR', imgAction: SwapImg, action: (id?: number) => openUpdateAvatar({ multiPassId: id }) },
    { nameAction: 'CLAIM GEAR', imgAction: SettingsImg, action: (id?: number) => openClaimGears(id) },
    { nameAction: 'SELL MULTIPASS', imgAction: ArrowImg, action: (id?: number) => window.open(`${linksOpenseaForItem.MultiPass}/${id}`, '_blank') },
  ], [openClaimGears, openMintAvatar, openUpdateAvatar]);

  const linksGear = useMemo(() => [
    { name: 'Apply Gear', method: () => openApplyGears() },
  ], [openApplyGears]);

  const actionsGear = useMemo(() => [
    { nameAction: 'APPLY GEAR', imgAction: ArrowImg, action: (id?: number) => openApplyGears({ gearId: id }) },
    { nameAction: 'SELL GEAR TOKEN', imgAction: ArrowImg, action: (id?: number) => window.open(`${linksOpenseaForItem.Gears}/${id}`, '_blank') },
  ], [openApplyGears]);

  const linksCitizenCard = useMemo(() => [
    { name: 'Upgrade', method: () => openUpgradeCards() },
    { name: 'Assign block', method: () => openAssignBlock() },
    { name: 'Claim Earnings', method: () => openClaimCard() },
  ], [openAssignBlock, openClaimCard, openUpgradeCards]);

  const actionsCitizenCard = useMemo(() => [
    { nameAction: 'ASSIGN BLOCK', imgAction: ArrowImg, action: (id?: number) => openAssignBlock(id) },
    { nameAction: 'REMOVE BLOCK', imgAction: ArrowImg, action: () => openRemoveBlock() },
    { nameAction: 'SELL CARD', imgAction: ArrowImg, action: (id?: number) => window.open(`${linksOpenseaForItem.CitizenCard}/${id}`, '_blank') },
    { nameAction: 'CLAIM EARNING', imgAction: ArrowImg, action: (id?: number) => openClaimCard(id) },
  ], [openAssignBlock, openClaimCard, openRemoveBlock]);

  return (
    <section className={cx(styles.blocks, className)}>
      <NftList
        key="Avatar"
        name="Avatars"
        titleModal="Avatar"
        links={linksAvatars} 
        isDisabledLinks={!isUserAuthenticated || !avatarsMetas.length}
        images={avatarsMetas}
        actions={actionsAvatars}
        isSmallBlock={isSmallBlock}
      />
      <NftList
        key="Blocks"
        name="Blocks"
        titleModal="Block"
        links={linksBlocks} 
        isDisabledLinks={!isUserAuthenticated || !blocksMetas.length}
        actions={actionsBlocks}
        images={blocksMetas}
        isSmallBlock={isSmallBlock}
      />
      <NftList
        key="MULTIPASSES"
        name="MULTIPASSES"
        titleModal="MULTIPASS"
        links={linksMultiPass}
        isDisabledLinks={!isUserAuthenticated || !multiPassMetas.length}
        images={multiPassMetas}
        isSmallBlock={isSmallBlock}
        actions={actionsMultiPass}
      />
      <NftList
        key="GEAR TOKENS"
        name="GEAR TOKENS"
        titleModal="GEAR TOKEN"
        links={linksGear}
        isDisabledLinks={!isUserAuthenticated || !gearsMetas.length}
        images={gearsMetas}
        isSmallBlock={isSmallBlock}
        actions={actionsGear}
      />
      <NftList
        key="A-ID CARDS"
        name="A-ID CARDS"
        titleModal="A-ID CARD"
        links={linksCitizenCard}
        isDisabledLinks={!isUserAuthenticated || !citizenCardMetas.length}
        actions={actionsCitizenCard}
        images={citizenCardMetas}
        isSmallBlock={isSmallBlock}
      />
      {modalUpdateAvatar}
      {modalApplyGears}
      {modalClaimGears}
      {modalMintAvatar}
      {modalAssignBlock}
      {modalClaimCard}
      {modalUpgradeCards}
      {modalRemoveBlock}
    </section>
  );
};
