import React, { FC, useCallback, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components';
import { MetamaskStatus } from 'types';
import { useAuth } from 'hooks';
import { LogoImg } from 'assets/img';
import { metamaskSelectors } from 'store/metamask/selectors';
import { metamaskConnect, metamaskDisconnect } from 'store/metamask/actionCreators';
import styles from './styles.module.css';

interface HeaderProps {
  className?: string,
}

export const Header: FC<HeaderProps> = ({ className = '' }) => {
  const dispatch = useDispatch();

  const {
    isUserAuthenticated, isLoading,
  } = useAuth();

  const isLostWallet = useSelector(metamaskSelectors.getProp('isLostWallet'));

  useEffect(() => {
    if (!isLostWallet) {
      dispatch(metamaskConnect());
    }
  }, [dispatch, isLostWallet]);

  const handleConnect = useCallback(() => {
    dispatch(metamaskConnect());
  }, [dispatch]);

  const handleDisconnect = useCallback(() => {
    dispatch(metamaskDisconnect({ status: MetamaskStatus.LOST }));
  }, [dispatch]);
  
  return (
    <div className={cx(styles.header, className)}>
      <img
        src={LogoImg}
        alt="logo"
        className={styles.logo}
      />
      <Button
        onClick={isUserAuthenticated ? handleDisconnect : handleConnect}
        isLoading={isLoading}
      >
        {isUserAuthenticated ? 'Disconnect' : 'Connect Wallet'}
      </Button>
    </div>
  );
};
