import React, {
  FC,
  useCallback,
  useState,
} from 'react';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Grid } from 'swiper';
import { PaginationOptions } from 'swiper/types';
import {
  ButtonIcon, 
} from 'components';
import { IconActiveImg } from 'assets/img';
import { containsTokenId } from 'utils';
import styles from './styles.module.css';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';

export type ChooseToken = {
  label: string,
  value: string,
  img: string,
  id: number,
};

interface ChooseTokenListProps {
  className?: string,
  countOnPage?: number,
  items: ChooseToken[],
  onChoose?: (tokens: ChooseToken[]) => void,
  isDisabledSelect?: boolean;
}

export const ChooseTokenList: FC<ChooseTokenListProps> = ({ 
  className = '', items, onChoose, countOnPage = 4, isDisabledSelect = true,
}) => {
  const [tokens, setTokens] = useState<ChooseToken[]>([]);

  const handleChoose = useCallback((token: ChooseToken) => {
    const updatedTokens = [...tokens];
    if (containsTokenId(updatedTokens, token.id)) {
      const res = updatedTokens.filter((item) => item.id !== token.id);
      setTokens(res);
      if (onChoose) onChoose(res);
    } else {
      updatedTokens.push(token);
      setTokens(updatedTokens);
      if (onChoose) onChoose(updatedTokens);
    }
  }, [tokens, onChoose]);

  const options: PaginationOptions = {
    clickable: true,
    bulletClass: styles.bullet_default,
    bulletActiveClass: styles.bullet_active,
    horizontalClass: styles.bullet_horizontal,
  };

  return (
    <div className={cx(styles.chooseToken, className)}>
      <div className={styles.slider}>
        <Swiper
          slidesPerView={countOnPage}
          spaceBetween={0}
          pagination={options}
          grid={{
            rows: 2,
          }}
          modules={[Pagination, Grid]}
          className={styles.swiper}
        >
          {items.map((token) => (
            <SwiperSlide key={token.label}>
              <ButtonIcon
                className={cx(
                  styles.img_button,
                  { [styles.isActiveItem]: isDisabledSelect && containsTokenId(tokens, token.id) },
                )}
                classNameImage={styles.img}
                onClick={() => isDisabledSelect && handleChoose(token)}
                imageURL={token.img}
              />
              {containsTokenId(tokens, token.id) && (
                <img
                  className={styles.bgActive}
                  src={IconActiveImg}
                  alt="active icon"
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
