import createReducer from 'utils/createReducer';
import { RequestStatus } from 'types';
import { AwakenedTokenState } from 'types/store/AwakenedTokenState';
import { AwakenedTokenActionTypes } from './actionsTypes';
import { awakenedTokenHandlers } from './handlers';

export const awakenedTokenInitialState: Readonly<AwakenedTokenState> = {
  balance: '',
  ui: {
    [AwakenedTokenActionTypes.GetData]: RequestStatus.INIT,
    [AwakenedTokenActionTypes.Approve]: RequestStatus.INIT,
  },
};

export default createReducer(awakenedTokenInitialState, awakenedTokenHandlers);
