import { takeLeading } from 'redux-saga/effects';
import { BlockActionTypes } from '../actionsTypes';
import { blockGetDataSaga } from './getData';
import { blockAssignCardSaga } from './assignCard';
import { blockRemoveCardSaga } from './removeCard';
import { blockWithdrawSaga } from './withdraw';

export default function* blockSaga() {
  yield takeLeading(BlockActionTypes.GetData, blockGetDataSaga);
  yield takeLeading(BlockActionTypes.AssignCard, blockAssignCardSaga);
  yield takeLeading(BlockActionTypes.RemoveCard, blockRemoveCardSaga);
  yield takeLeading(BlockActionTypes.Withdraw, blockWithdrawSaga);
}
