import { call, put, select } from 'redux-saga/effects';
import { RequestStatus } from 'types';
import { sagaExceptionHandler } from 'utils';
import { apiAuthLogout } from 'api';
import { authSelectors } from 'store/auth/selectors';
import { authLogout, authSetState, authSetStatus } from 'store/auth/actionCreators';

export function* authLogoutSaga({
  type,
}: ReturnType<typeof authLogout>) {
  try {
    yield put(authSetStatus({ type, status: RequestStatus.REQUEST }));
    
    const accessToken: string = yield select(authSelectors.getProp('accessToken'));
    if(accessToken) {
      try {
        yield call(apiAuthLogout);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('accessToken is expired');
      }
    }

    yield put(authSetState({
      accessToken: undefined,
      refreshToken: undefined,
      addressWallet: '',
    }));

    yield put(authSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(authSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
