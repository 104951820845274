import { RequestStatus } from 'types';
import { AwakenedTokenState } from 'types/store/AwakenedTokenState';
import { AwakenedTokenActionTypes } from './actionsTypes';

export const awakenedTokenSetState = (payload: Partial<AwakenedTokenState>) => ({
  type: AwakenedTokenActionTypes.SetState,
  payload,
});

export const awakenedTokenSetStatus = (
  payload: { type: AwakenedTokenActionTypes, status: RequestStatus },
) => ({
  type: AwakenedTokenActionTypes.SetStatus,
  payload,
});

export const awakenedTokenGetData = () => ({
  type: AwakenedTokenActionTypes.GetData,
});

export const awakenedTokenApprove = () => ({
  type: AwakenedTokenActionTypes.Approve,
});
