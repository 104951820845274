import { ApiEndpoint } from 'appConstants';
import { findStringMetadata, throwApiError } from 'utils';
import {
  ImageNftTestImg, 
} from 'assets/img';
import { api } from 'api';
import { MetaData } from 'types';

type ProfileMetaDataDto = {
  name: string,
  description: string,
  external_url: string,
  image: string,
  animation_url: string,
  attributes: { trait_type: string, value: string }[]
};

type ProfileBlockMetaDataDto = {
  id: number,
  level: string,
  score: number,
  city: string,
  front_plot: string,
  back_plot: string,
  left_plot: string,
  right_plot: string,
  image_url: string
};

const renameMetaData = (
  { metadata, prefixName = '', listParam = [] }
  : { metadata?: ProfileMetaDataDto, prefixName?: string, listParam?: string[] },
): Pick<MetaData, 'name' | 'description' | 'imageUrl' | 'videoUrl' | 'params'> | null => {
  if (!metadata) return null;
  let resParams: { name: string, value: string } [] = [];
  const params = metadata?.attributes
    .map((param) => ({ 
      name: prefixName + param.trait_type, 
      value: param.value,
    })) ?? [];
  resParams = [...params];
  if (listParam.length > 0 && params.length > 0) {
    resParams = listParam
      .map(
        (name: string) => params.find((param) => (prefixName + name) === param.name),
      )
      .filter((it) => it) as { name: string, value: string }[];
  }

  return {
    name: metadata.name,
    description: metadata.description,
    imageUrl: metadata.image,
    videoUrl: metadata.animation_url,
    params: resParams,
  };
};

const renameMetaDataForBlock = (metadata: ProfileBlockMetaDataDto) => ({
  name: `Block #${metadata.id}`,
  description: `Block #${metadata.id}`,
  imageUrl: metadata.image_url,
  videoUrl: metadata.image_url,
  level: metadata.level, 
  params: [
    {
      name: '//Level', 
      value: metadata.level,
    },
    {
      name: '//Score', 
      value: metadata.score,
    },
    {
      name: '//City', 
      value: metadata.city,
    },
    {
      name: '//Front plot', 
      value: metadata.front_plot,
    },
    {
      name: '//Back plot', 
      value: metadata.back_plot,
    },
    {
      name: '//Left plot', 
      value: metadata.left_plot,
    },
    {
      name: '//Right plot', 
      value: metadata.right_plot,
    },
  ], 
});

export const apiProfileMultiPass = async (): Promise<MetaData[]> => {
  const { data } = await api.get(
    `${ApiEndpoint.ProfileMultiPass}`,
  );

  if (data.error) {
    throwApiError(data);
  }

  return data.map((item: { metadata: ProfileMetaDataDto, multipassId: string }) => {
    const mt = renameMetaData({ 
      metadata: item.metadata, 
      prefixName: '//',
      listParam: ['ID', 'Level'], 
    });
    const res = {
      id: +item.multipassId,
      ...mt,
    };
    return {
      ...res,
      level: mt ? findStringMetadata(mt.params, 'level')?.replace('Level ', '') : '',
      isClaimedGear: mt ? findStringMetadata(mt.params, 'gear token claimed') === 'Yes' : false, 
    };
  });
};

export const apiProfileBlock = async (): Promise<MetaData[]> => {
  const { data } = await api.get(
    `${ApiEndpoint.ProfileBlock}`,
  );

  if (data.error) {
    throwApiError(data);
  }

  return data.map((
    item: { metadata: ProfileBlockMetaDataDto, blockId: string, citizenCardId: string },
  ) => ({
    id: +item.blockId,
    cardId: +item.citizenCardId,
    ...renameMetaDataForBlock(item.metadata),
  }));
};

export const apiProfileAvatar = async (): Promise<MetaData[]> => {
  const { data } = await api.get(
    `${ApiEndpoint.ProfileAvatar}`,
  );

  if (data.error) {
    throwApiError(data);
  }

  return data.map((item: { metadata: ProfileMetaDataDto, avatarId: string }) => {
    const mt = renameMetaData({ 
      metadata: item.metadata, 
      prefixName: '//',  
      listParam: ['Persona', 'City', 'Level'], 
    });

    const res = {
      id: +item.avatarId,
      ...mt,
    };
    return {
      ...res,
      level: mt ? findStringMetadata(mt.params, 'level') : '',
      isClaimedGear: mt ? findStringMetadata(mt.params, 'gear token claimed') === 'Yes' : false, 
    };
  });
};

export const apiProfileCitizenCard = async (): Promise<MetaData[]> => {
  const { data } = await api.get(
    `${ApiEndpoint.ProfileCitizenCard}`,
  );

  if (data.error) {
    throwApiError(data);
  }

  return data.map((item: { metadata: ProfileMetaDataDto, citizenCardId: string }) => {
    const mt = renameMetaData({ 
      metadata: item.metadata, 
      prefixName: '//',        
      listParam: ['Persona', 'City', 'Level', 'Blocks Assigned', 'Earnings'],
    });

    const res = {
      id: +item.citizenCardId,
      ...mt,
    };
    return {
      imageUrl: ImageNftTestImg,
      ...res,
      level: mt ? findStringMetadata(mt.params, 'level') : '',
      isClaimedGear: mt ? findStringMetadata(mt.params, 'gear token claimed') === 'Yes' : false,  
    };
  });
};

export const apiProfileGears = async (): Promise<MetaData[]> => {
  const { data } = await api.get(
    `${ApiEndpoint.ProfileGear}`,
  );

  if (data.error) {
    throwApiError(data);
  }

  return data.map((item: { metadata?: ProfileMetaDataDto, gearId: string }) => ({
    id: +item.gearId,
    ...renameMetaData({
      metadata: item.metadata ?? {
        name: `Gear #${item.gearId}`,
        description: `Gear #${item.gearId}`,
        external_url: ImageNftTestImg,
        image: ImageNftTestImg,
        animation_url: ImageNftTestImg,
        attributes: [], 
      }, 
    }),
  }));
};
