import { ActionFn } from 'types/redux';
import { GearsState } from 'types/store/GearsState';
import { gearsSetState, gearsSetStatus } from './actionCreators';
import { GearsActionTypes } from './actionsTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GearsHandlerFn<F extends (...args : any) => any> = ActionFn<GearsState, ReturnType<F>>;

const setState: GearsHandlerFn<typeof gearsSetState> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: GearsHandlerFn<typeof gearsSetStatus> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const gearsHandlers = {
  [GearsActionTypes.SetState]: setState,
  [GearsActionTypes.SetStatus]: setStatus,
};
