import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus, Unwrap,
} from 'types';
import { 
  balanceOfBlockApi, getBlockInfoBlockApi, getRewardsBlockApi, getRewardsPaidBlockApi,
  apiProfileBlock,
} from 'api';
import { 
  blockSetStatus, blockGetData, blockSetState,
} from 'store/block/actionCreators';

export function* blockGetDataSaga({ type }: ReturnType<typeof blockGetData>) {
  try{
    yield put(blockSetStatus({ type, status: RequestStatus.REQUEST }));

    const balance: Unwrap<typeof balanceOfBlockApi> = 
      yield call(balanceOfBlockApi);
    
    yield put(blockSetState({ balance }));

    const blocksMetas: Unwrap<typeof apiProfileBlock> = 
      yield call(apiProfileBlock);

    const cardIds = blocksMetas.map((block) => block.id);

    const blocksInfo: Array<Unwrap<typeof getBlockInfoBlockApi>> = 
      yield Promise.all(cardIds.map((cardId) => getBlockInfoBlockApi(cardId)));
  
    const blocksRewards: Array<Unwrap<typeof getRewardsBlockApi>> = 
      yield Promise.all(cardIds.map((cardId) => getRewardsBlockApi(cardId)));
    
    const blocksRewardsPaid: Array<Unwrap<typeof getRewardsPaidBlockApi>> = 
      yield Promise.all(cardIds.map((cardId) => getRewardsPaidBlockApi(cardId)));
    
    yield put(blockSetState({ 
      blocksInfo, blocksRewards, blocksRewardsPaid, blocksMetas,
    }));

    yield put(blockSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(blockSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
