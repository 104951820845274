import { call, put } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus,
} from 'types';
import { 
  upgradeCardCitizenCardApi,
} from 'api';
import { multiPassGetData } from 'store/multiPass/actionCreators';
import { 
  citizenCardSetStatus, citizenCardUpgrade, citizenCardGetData,
} from 'store/citizenCard/actionCreators';

export function* citizenCardUpgradeSaga({ type, payload }: ReturnType<typeof citizenCardUpgrade>) {
  try{
    yield put(citizenCardSetStatus({ type, status: RequestStatus.REQUEST }));

    yield call(upgradeCardCitizenCardApi, payload.cardIds, payload.multiPassIds);

    yield put(citizenCardGetData());
    yield put(multiPassGetData());
    if(payload.callback) payload.callback();
    yield put(citizenCardSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(citizenCardSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
