import { ActionFn } from 'types/redux';
import { AwakenedTokenState } from 'types/store/AwakenedTokenState';
import { awakenedTokenSetState, awakenedTokenSetStatus } from './actionCreators';
import { AwakenedTokenActionTypes } from './actionsTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, max-len
type AwakenedTokenHandlerFn<F extends (...args : any) => any> = ActionFn<AwakenedTokenState, ReturnType<F>>;

const setState: AwakenedTokenHandlerFn<typeof awakenedTokenSetState> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: AwakenedTokenHandlerFn<typeof awakenedTokenSetStatus> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const awakenedTokenHandlers = {
  [AwakenedTokenActionTypes.SetState]: setState,
  [AwakenedTokenActionTypes.SetStatus]: setStatus,
};
