import { ActionFn } from 'types/redux';
import { LeaderState } from 'types/store/LeaderState';
import { leaderSetState, leaderSetStatus } from './actionCreators';
import { LeaderActionTypes } from './actionsTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LeaderHandlerFn<F extends (...args : any) => any> = ActionFn<LeaderState, ReturnType<F>>;

const setState: LeaderHandlerFn<typeof leaderSetState> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: LeaderHandlerFn<typeof leaderSetStatus> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const leaderHandlers = {
  [LeaderActionTypes.SetState]: setState,
  [LeaderActionTypes.SetStatus]: setStatus,
};
