import { RequestStatus } from 'types';
import { GearsState } from 'types/store/GearsState';
import { GearsActionTypes } from './actionsTypes';

export const gearsSetState = (payload: Partial<GearsState>) => ({
  type: GearsActionTypes.SetState,
  payload,
});

export const gearsSetStatus = (
  payload: { type: GearsActionTypes, status: RequestStatus },
) => ({
  type: GearsActionTypes.SetStatus,
  payload,
});

export const gearsGetData = () => ({
  type: GearsActionTypes.GetData,
});

export const gearsApplyToAvatar = (
  payload: { gearIds: number[], avatarIds: number[], callback?: () => void },
) => ({
  type: GearsActionTypes.ApplyToAvatar,
  payload,
});

export const gearsClaim = (
  payload: { multiPassIds: number[], callback?: () => void },
) => ({
  type: GearsActionTypes.Claim,
  payload,
});
