import { ApiEndpoint } from 'appConstants';
import { throwApiError, formatEth } from 'utils';
import { api } from 'api';
import { LeaderBoard } from 'types';

export const apiLeaderBoard = async (): Promise<LeaderBoard[]> => {
  const { data } = await api.get(
    `${ApiEndpoint.Leaderboard}`,
  );

  if (data.error) {
    throwApiError(data);
  }

  const decimals = 18;

  return data.map((item: LeaderBoard) => ({
    ...item,
    balance: formatEth(item.balance, decimals),
  }));
};
