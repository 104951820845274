import { getAddress, getContract } from 'api/ethereum';
import { AvatarAbi } from 'assets/abi';
import { contracts } from 'appConstants';

export const approveAvatarApi = async (addressApprove: string, isApprove: boolean) => {
  const contract = getContract(contracts.Avatar, AvatarAbi);
  const address = await getAddress(); 

  return contract.methods
    .setApprovalForAll(addressApprove, isApprove)
    .send({ from: address });
};

export const isApprovedForAllAvatarApi = async (operatorAddress: string): Promise<boolean> => {
  const contract = getContract(contracts.Avatar, AvatarAbi);
  const address = await getAddress(); 

  return contract.methods
    .isApprovedForAll(address, operatorAddress)
    .call();
};

export const mintAvatarApi = async (multiPassIds: number[]) => {
  const contract = getContract(contracts.Avatar, AvatarAbi);
  const address = await getAddress();

  return contract.methods
    .mintAvatar(address, multiPassIds)
    .send({ from: address });
};

export const updateAvatarApi = async (avatarIds: number[], multiPassIds: number[]) => {
  const contract = getContract(contracts.Avatar, AvatarAbi);
  const address = await getAddress();

  return contract.methods
    .updateAvatar(address, avatarIds, multiPassIds)
    .send({ from: address });
};

export const applyGearAvatarApi = async (avatarId: number, holder: string, gearId: number) => {
  const contract = getContract(contracts.Avatar, AvatarAbi);
  const address = await getAddress();

  return contract.methods
    .applyGear(avatarId, holder, gearId)
    .send({ from: address });
};

export const getDecimalsAvatarApi = async (): Promise<number> => {
  const contract = getContract(contracts.Avatar, AvatarAbi);
  const result = await contract.methods.decimals().call();
  return result;
};

export const balanceOfAvatarApi = async (id: number): Promise<string> => {
  const contract = getContract(contracts.Avatar, AvatarAbi);
  const address = await getAddress(); 

  return contract.methods
    .balanceOf(address, id)
    .call();
};

export const balanceBatchAvatarApi = async (ids: number[]): Promise<string> => {
  const contract = getContract(contracts.Avatar, AvatarAbi);
  const address = await getAddress(); 

  return contract.methods
    .balanceOfBatch(address, ids)
    .call();
};

export const withdrawAvatarApi = async (amount: number) => {
  const contract = getContract(contracts.Avatar, AvatarAbi);
  const address = await getAddress();

  return contract.methods
    .withdraw(amount)
    .send({ from: address });
};
