import { ActionFn } from 'types/redux';
import { AvatarState } from 'types/store/AvatarState';
import { avatarSetState, avatarSetStatus } from './actionCreators';
import { AvatarActionTypes } from './actionsTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AvatarHandlerFn<F extends (...args : any) => any> = ActionFn<AvatarState, ReturnType<F>>;

const setState: AvatarHandlerFn<typeof avatarSetState> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: AvatarHandlerFn<typeof avatarSetStatus> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const avatarHandlers = {
  [AvatarActionTypes.SetState]: setState,
  [AvatarActionTypes.SetStatus]: setStatus,
};
