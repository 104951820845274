import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'types';
import { ChooseItemsModal } from 'components';
import { IconRefreshImg } from 'assets/img';
import { citizenCardUpgrade } from 'store/citizenCard/actionCreators';
import { CitizenCardActionTypes } from 'store/citizenCard/actionsTypes';
import { citizenCardSelectors } from 'store/citizenCard/selectors';
import { multiPassSelectors } from 'store/multiPass/selectors';

export const useUpdateCard = () => {
  const dispatch = useDispatch();
  
  const statusUpdate = useSelector(citizenCardSelectors.getStatus(CitizenCardActionTypes.Upgrade));
  const citizenCardMetas = useSelector(citizenCardSelectors.getProp('citizenCardMetas'));

  const citizenCards = useMemo(() => citizenCardMetas.map((item) => ({
    id: item.id,
    label: `// ID ${item.id}`,
    value: `// Lvl ${item.level}`,
    img: item.imageUrl,
  })), [citizenCardMetas]);

  const multiPassMetas = useSelector(multiPassSelectors.getProp('multiPassMetas'));

  const [cardIds, setCardIds] = useState<number[]>([]);
  const [multiPassIds, setMultiPassIds] = useState<number[]>([]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [nextStepModal, setNextStepModal] = useState<boolean>(false);
  
  const multiPass = useMemo(() => multiPassMetas
    .map((item) => ({
      id: item.id,
      label: `// ID ${item.id}`,
      value: `// Lvl ${item.level}`,
      img: item.imageUrl,
    })), [multiPassMetas]);
  
  const nextStep = useCallback((cardIdsNew: number[]) => {
    if(cardIdsNew.length > 0) {
      setCardIds(cardIdsNew);
      setOpenModal(false); 
      setNextStepModal(true);
    }
  }, []);

  const handleFistStepOpen = useCallback((
    { cardIds: cardIdsNew, multiPassIds: multiPassIdsNew }
    : { cardIds?: number[], multiPassIds?: number[] } = {},
  ) => {
    if(cardIdsNew) {
      setCardIds(cardIdsNew);
      setNextStepModal(true);
    } else if (multiPassIdsNew) {
      setMultiPassIds(multiPassIdsNew);
      setOpenModal(true); 
    } else {
      setOpenModal(true); 
    }
  }, []);

  const handleCloseAll = useCallback(() => {
    setOpenModal(false); 
    setNextStepModal(false); 
    setCardIds([]);
    setMultiPassIds([]);
  }, []);

  const handleUpdate = useCallback((multiPassIdsNew: number[]) => {
    if(cardIds.length === multiPassIds.length) {
      dispatch(citizenCardUpgrade({
        cardIds,
        multiPassIds: multiPassIdsNew, 
        callback: handleCloseAll,
      }));
    }
  }, [cardIds, dispatch, handleCloseAll, multiPassIds]);

  return {
    openUpgradeCards: handleFistStepOpen,
    modalUpgradeCards: [
      <ChooseItemsModal
        key="useUpdateCard-Next step"
        buttonLabel="Next step"
        items={citizenCards}
        label="Select the Card(s) you want to upgrade below."
        title="UPGRADE CARDS"
        isOpen={openModal}
        onClose={handleCloseAll}
        onButtonClick={nextStep}
        defaultIds={cardIds}
        onChoose={setCardIds}
        buttonIsDisabled={cardIds.length === 0}
      />,
      <ChooseItemsModal
        key="useUpdateAvatar-Upgrade Card"
        buttonLabel="Upgrade CARD"
        buttonImg={IconRefreshImg}
        items={multiPass}
        label="Select the MultiPass(s) you want to burn below."
        title="Select the MultiPass(s)"
        isOpen={nextStepModal}
        onClose={handleCloseAll}
        onButtonClick={handleUpdate}
        defaultIds={multiPassIds}
        onChoose={setMultiPassIds}
        buttonIsDisabled={cardIds.length !== multiPassIds.length}
        buttonIsLoading={statusUpdate === RequestStatus.REQUEST}
      />,
    ],
  };
};
