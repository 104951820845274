import { RequestStatus } from 'types';
import { RewardsState } from 'types/store/RewardsState';
import { RewardsActionTypes } from './actionsTypes';

export const rewardsSetState = (payload: Partial<RewardsState>) => ({
  type: RewardsActionTypes.SetState,
  payload,
});

export const rewardsSetStatus = (
  payload: { type: RewardsActionTypes, status: RequestStatus },
) => ({
  type: RewardsActionTypes.SetStatus,
  payload,
});

export const rewardsGetData = () => ({
  type: RewardsActionTypes.GetData,
});

export const rewardsClaimAll = () => ({
  type: RewardsActionTypes.ClaimAll,
});
