import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'types';
import { contracts } from 'appConstants';
import { ChooseItemsModal } from 'components';
import { IconPlayImg } from 'assets/img';
import { avatarMint } from 'store/avatar/actionCreators';
import { AvatarActionTypes } from 'store/avatar/actionsTypes';
import { MultiPassActionTypes } from 'store/multiPass/actionsTypes';
import { multiPassApprove } from 'store/multiPass/actionCreators';
import { avatarSelectors } from 'store/avatar/selectors';
import { multiPassSelectors } from 'store/multiPass/selectors';

export const useMintAvatar = () => {
  const dispatch = useDispatch();
  
  const statusMint = useSelector(avatarSelectors.getStatus(AvatarActionTypes.Mint));
  const statusApprove = useSelector(multiPassSelectors.getStatus(MultiPassActionTypes.Approve));
  const isApproveForAvatar = useSelector(multiPassSelectors.getProp('isApproveForAvatar'));
  const multiPassMetas = useSelector(multiPassSelectors.getProp('multiPassMetas'));

  const multiPass = useMemo(() => multiPassMetas
    .filter((item) => item?.level && +item.level > 0)
    .map((item) => ({
      id: item.id,
      label: `// ID ${item.id}`,
      value: `// Lvl ${item.level}`,
      img: item.imageUrl,
    })), [multiPassMetas]);
  
  const [multiPassIds, setMultiPassIds] = useState<number[]>([]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  
  const handleApprove = useCallback(() => {
    dispatch(multiPassApprove({ address: contracts.Avatar }));
  }, [dispatch]);
  
  const handleFistStepOpen = useCallback((multiPassId?: number) => {
    setOpenModal(true); 
    if (multiPassId) setMultiPassIds([multiPassId]);
  }, []);

  const handleFistStepClose = useCallback(() => {
    setOpenModal(false); 
    setMultiPassIds([]);
  }, []);
  
  const handleMint = useCallback((multiPassIdsNew: number[]) => {
    if(multiPassIdsNew.length) {
      dispatch(avatarMint({
        multiPassIds: multiPassIdsNew,
        callback: handleFistStepClose,
      }));
    }
  }, [dispatch, handleFistStepClose]);

  return {
    openMintAvatar: handleFistStepOpen,
    modalMintAvatar: [
      <ChooseItemsModal
        key="useMintAvatar-MINT AVATAR"
        items={multiPass}
        label={`Burn a MultiPass to reveal an Avatar.  Select from your
        available MultiPass(es) below.
        `}
        title="BURN A MULTIPASS - MINT AN AVATAR"
        isOpen={openModal}
        onClose={handleFistStepClose}
        isOnlyOne={false}
        defaultIds={multiPassIds}
        onChoose={setMultiPassIds}
        buttonLabel="MINT AVATAR"
        buttonImg={IconPlayImg}
        onButtonClick={handleMint}
        buttonIsDisabled={isApproveForAvatar === false || multiPassIds.length === 0}
        buttonIsLoading={statusMint === RequestStatus.REQUEST}
        buttonSecondLabel="Approve"
        onButtonSecondClick={!isApproveForAvatar ? handleApprove : undefined}
        buttonSecondIsDisabled={multiPassIds.length === 0}
        buttonSecondIsLoading={statusApprove === RequestStatus.REQUEST}
      />,
    ],
  };
};
