import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'types';
import { ChooseItemsModal } from 'components';
import { IconSettingImg } from 'assets/img';
import { citizenCardWithdrawRewards } from 'store/citizenCard/actionCreators';
import { CitizenCardActionTypes } from 'store/citizenCard/actionsTypes';
import { citizenCardSelectors } from 'store/citizenCard/selectors';

export const useClaimCard = () => {
  const dispatch = useDispatch();
  
  const statusClaim = useSelector(
    citizenCardSelectors.getStatus(CitizenCardActionTypes.WithdrawRewards),
  );

  const [cardIds, setCardIdsIds] = useState<number[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  
  const handleFistStepOpen = useCallback((id?: number) => {
    setOpenModal(true); 
    if (id) setCardIdsIds([id]);
  }, []);

  const handleFistStepClose = useCallback(() => {
    setOpenModal(false); 
    setCardIdsIds([]);
  }, []);

  const citizenCardMetas = useSelector(citizenCardSelectors.getProp('citizenCardMetas'));

  const citizenCard = useMemo(() => citizenCardMetas.map((item) => ({
    id: item.id,
    label: `// ID ${item.id}`,
    value: `// Lvl ${item.level}`,
    img: item.imageUrl,
  })), [citizenCardMetas]);
  
  const handleClaim = useCallback((cardIdsNew: number[]) => {
    if(cardIdsNew.length) {
      dispatch(citizenCardWithdrawRewards({
        cardId: cardIdsNew[0],
        callback: handleFistStepClose,
      }));
    }
  }, [dispatch, handleFistStepClose]);

  return {
    openClaimCard: handleFistStepOpen,
    modalClaimCard: [
      <ChooseItemsModal
        key="useClaimCard-CLAIM A-ID Cards"
        buttonLabel="CLAIM A-ID Cards"
        buttonImg={IconSettingImg}
        items={citizenCard}
        label={`You can claim a A-ID Cards Token .
        Select your A-ID Cards Token(es) below.
        `}
        title="CLAIM A-ID Cards TOKEN(S)"
        isOpen={openModal}
        onClose={handleFistStepClose}
        onButtonClick={handleClaim}
        onChoose={setCardIdsIds}
        buttonIsDisabled={cardIds.length === 0}
        buttonIsLoading={statusClaim === RequestStatus.REQUEST}
      />,
    ],
  };
};
