import React, { FC } from 'react';
import cx from 'classnames';
import { Modal } from 'components/Modal';
import styles from './styles.module.css';

export type ModalInfo = {
  name: string
  value: string
};

interface ModalWithInfoProps {
  isOpen: boolean,
  onClose: () => void,
  className?: string,
  classNameInfoLabel?: string,
  classNameInfoValue?: string,
  buttonsClassName?: string,
  title?: string,
  info?: ModalInfo[],
  children?: React.ReactNode,
  img?: string,
}

export const ModalWithInfo: FC<ModalWithInfoProps> = ({
  isOpen, onClose,
  title,
  info,
  img,
  children,
  className = '',
  classNameInfoLabel = '',
  classNameInfoValue = '',
  buttonsClassName = '',
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    title={title}
  >
    <div className={cx(styles.content, className)}>
      <img
        alt="avatar"
        className={styles.avatar}
        src={img}
      />
      {info && (
        <div className={styles.info_container}>
          {info.map(({ name, value }, index) => (
            <div
              className={styles.info}
              // eslint-disable-next-line react/no-array-index-key
              key={`${name}_${value}_${index}`}
            >
              <p className={cx(styles.info_label, classNameInfoLabel)}>{name}</p>
              <p className={cx(styles.info_value, classNameInfoValue)}>{value}</p>
            </div>
          ))}
        </div>
      )}
    </div>
    <div className={cx(styles.buttons, buttonsClassName)}>
      {children}
    </div>
  </Modal>
);
