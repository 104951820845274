import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus, Unwrap,
} from 'types';
import { apiLeaderBoard } from 'api';
import { 
  leaderSetStatus, leaderGetData, leaderSetState,
} from 'store/leader/actionCreators';

export function* leaderGetDataSaga({ type }: ReturnType<typeof leaderGetData>) {
  try{
    yield put(leaderSetStatus({ type, status: RequestStatus.REQUEST }));

    const leaderBoard: Unwrap<typeof apiLeaderBoard> = yield call(apiLeaderBoard);
    
    yield put(leaderSetState({ leaderBoard }));

    yield put(leaderSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(leaderSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
