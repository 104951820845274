import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import { withdrawAllRewardsCitizenCardApi, withdrawAllRewardsBlockApi } from 'api';
import {
  RequestStatus,
} from 'types';
import { 
  rewardsSetStatus, rewardsGetData, rewardsClaimAll,
} from 'store/rewards/actionCreators';

export function* rewardsClaimAllSaga({ type }: ReturnType<typeof rewardsClaimAll>) {
  try{
    yield put(rewardsSetStatus({ type, status: RequestStatus.REQUEST }));
    
    yield call(withdrawAllRewardsCitizenCardApi);
    yield call(withdrawAllRewardsBlockApi);

    yield put(rewardsGetData());
    
    yield put(rewardsSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(rewardsSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
