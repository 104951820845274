import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { routes } from 'appConstants';

import cx from 'classnames';
import styles from './styles.module.css';

interface NavItemProps {
  name: string,
  link: string,
}

export const NavItem: FC<NavItemProps> = ({ name = '', link }) => (
  <NavLink
    to={link}
    className={({ isActive }) => cx(
      styles.navItem,
      {
        [styles.navItem]: !isActive,
        [styles.navItemActive]: isActive,
      },
    )}
  >
    {name}
  </NavLink>
);

interface NavigationProps {
  className?: string
}

export const Navigation: FC<NavigationProps> = ({ className = '' }) => (
  <div className={cx(styles.navigation, className)}>
    <NavItem
      name="//Dashboard"
      link={routes.dashboard.root}
    />
    <NavItem 
      name="//Marketplace" 
      link={routes.marketplace.root}
    />
    <NavItem 
      name="//A-ID Cards"
      link={routes.idCard.root} 
    />
    <NavItem 
      name="//Blocks" 
      link={routes.blocks.root} 
    />
    <NavItem 
      name="//Gear Tokens" 
      link={routes.gears.root} 
    />
    <NavItem 
      name="//MultiPasses" 
      link={routes.multiPass.root} 
    />
    <NavItem 
      name="//Exit Containment" 
      link={routes.convert.root}
    />
  </div>
);
