import React, { FC } from 'react';
import { Welcome } from 'components';
import { ClaimTokens, NftGrid } from 'containers';

export const Dashboard: FC = () => (
  <>
    <Welcome
      title="Welcome to the" 
      subTitle="awakened universe"
      description={`
        Manage your Avatars, A-ID cards, Blocks, Gear Tokens and MultiPasses in one place. 
        Mint, claim, sell, and upgrade all of your digital assets with ease.
      `} 
    />
    <ClaimTokens />
    <NftGrid />
  </>
);
