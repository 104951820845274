import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.css';

export type LinkItem = {
  name: string;
  method: () => void;
};

type ButtonLinksProps = {
  className?: string;
  links: LinkItem[];
  isDisabledLinks?: boolean;
};

export const ButtonLinks: FC<ButtonLinksProps> = ({
  className = '', links, isDisabledLinks,
}) => (
  <div className={cx(styles.navLinks, className)}>
    {links.map(({ name, method }) => (
      <button
        key={name}
        className={cx(styles.itemLink, { [styles.isDisabledLink]: isDisabledLinks })}
        onClick={method}
        disabled={isDisabledLinks}
      >
        {name}
      </button> 
    ))}
  </div>
);
