import { RequestStatus } from 'types';
import { LeaderState } from 'types/store/LeaderState';
import { LeaderActionTypes } from './actionsTypes';

export const leaderSetState = (payload: Partial<LeaderState>) => ({
  type: LeaderActionTypes.SetState,
  payload,
});

export const leaderSetStatus = (
  payload: { type: LeaderActionTypes, status: RequestStatus },
) => ({
  type: LeaderActionTypes.SetStatus,
  payload,
});

export const leaderGetData = () => ({
  type: LeaderActionTypes.GetData,
});
