import React from 'react';
import { Route, Routes as RoutesDom } from 'react-router-dom';
import { routes } from 'appConstants';
import { Layout, LeaderBoard, Actions } from 'containers';
import { 
  Dashboard, Blocks, Market, CitizenCard, Convert, Gears, MultiPass,
} from 'pages';

export const Routes = () => (
  <RoutesDom>
    <Route
      path="/"
      element={(
        <Layout leftPanel={(
          <>
            <LeaderBoard />
            <Actions />
          </>
        )}
        />
      )}
    >
      <Route
        index
        element={<Dashboard />}
      />
      <Route
        path={routes.marketplace.root}
        element={<Market />}
      />
      <Route
        path={routes.idCard.root}
        element={<CitizenCard />}
      />
      <Route
        path={routes.convert.root}
        element={<Convert />}
      />
      <Route
        path={routes.blocks.root}
        element={<Blocks />}
      />
      <Route
        path={routes.gears.root}
        element={<Gears />}
      />
      <Route
        path={routes.multiPass.root}
        element={<MultiPass />}
      />
    </Route>
  </RoutesDom>
);
