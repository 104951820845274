import React, { FC } from 'react';
import cx from 'classnames';
import { motion } from 'framer-motion';
import styles from './styles.module.css';

const spinTransition = {
  repeat: Infinity,
  ease: 'linear',
  duration: 1,
};

export enum TypeButton {
  gray = 'gray',
  red = 'red',
  link = 'link',
}
interface ButtonProps {
  className?: string,
  classNameIcon?: string,
  children: React.ReactNode,
  icon?: string,
  type?: TypeButton,
  onClick: () => void,
  isLoading?: boolean,
  isDisabled?: boolean,
}

export const Button: FC<ButtonProps> = ({
  className = '', classNameIcon = ' ', children, onClick, icon, type = TypeButton.gray, isLoading, isDisabled,
}) => (
  <motion.button
    className={cx(styles.button, {
      [styles.buttonGray]: type === TypeButton.gray,
      [styles.buttonRed]: type === TypeButton.red,
      [styles.buttonLink]: type === TypeButton.link,
      [styles.buttonDisabled]: isDisabled,
    }, className)}
    onClick={!isDisabled ? onClick : () => {}}
    whileHover={!isDisabled ? {
      opacity: 0.8,
      transition: { duration: 0.5 },
    } : {}}
    whileTap={!isDisabled ? { scale: 0.9 } : {}}
  >
    {!isLoading && (
      <>
        {children}
        {icon && (
        <img
          src={icon}
          alt="icon"
          className={cx(styles.icon, classNameIcon)}
        />
        )}
      </>
    )}
    {isLoading && (
      <motion.span
        className={styles.circleStyle}
        animate={{ rotate: 360 }}
        transition={spinTransition}
      />
    )}
  </motion.button>
);
