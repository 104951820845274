import { call, put } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus, Unwrap,
} from 'types';
import { 
  withdrawRewardsCitizenCardApi, getRewardsCitizenCardApi,
} from 'api';
import { 
  citizenCardSetStatus, citizenCardWithdrawRewards,
} from 'store/citizenCard/actionCreators';
import {
  rewardsGetData,
} from 'store/rewards/actionCreators';

export function* citizenCardWithdrawRewardsSaga(
  { type, payload }: ReturnType<typeof citizenCardWithdrawRewards>,
) {
  try{
    yield put(citizenCardSetStatus({ type, status: RequestStatus.REQUEST }));

    const balanceRewards: Unwrap<typeof getRewardsCitizenCardApi> = 
      yield call(getRewardsCitizenCardApi, payload.cardId);

    yield call(withdrawRewardsCitizenCardApi, payload.cardId, balanceRewards);
    
    if(payload.callback) payload.callback();
    yield put(rewardsGetData());

    yield put(citizenCardSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(citizenCardSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
