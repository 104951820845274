export default [{ inputs: [{ internalType: 'address', name: 'centralDAO', type: 'address' }, { internalType: 'address', name: 'centralFundBuffer', type: 'address' }, { internalType: 'address', name: 'team', type: 'address' }, { internalType: 'uint8', name: 'decimals_', type: 'uint8' }], stateMutability: 'nonpayable', type: 'constructor' }, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'owner', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'spender', type: 'address', 
  }, {
    indexed: false, internalType: 'uint256', name: 'value', type: 'uint256', 
  }],
  name: 'Approval',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'previousOwner', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'newOwner', type: 'address', 
  }],
  name: 'OwnershipTransferred',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'from', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'to', type: 'address', 
  }, {
    indexed: false, internalType: 'uint256', name: 'value', type: 'uint256', 
  }],
  name: 'Transfer',
  type: 'event', 
}, {
  inputs: [], name: 'CENTRAL_DAO_PERSENTAGE', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'CENTRAL_FUND_BUFFER_PERSENTAGE', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'INITIAL_CAP', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'TEAM_PERSENTAGE', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'account', type: 'address' }], name: 'allow', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'owner', type: 'address' }, { internalType: 'address', name: 'spender', type: 'address' }], name: 'allowance', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'approve', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'account', type: 'address' }], name: 'balanceOf', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'decimals', outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' }], name: 'decreaseAllowance', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'account', type: 'address' }], name: 'deny', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [], name: 'getCentralDAO', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'getCentralFundBuffer', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'getTeam', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'account', type: 'address' }], name: 'getWards', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, { internalType: 'uint256', name: 'addedValue', type: 'uint256' }], name: 'increaseAllowance', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: '_to', type: 'address' }, { internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'mint', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [], name: 'name', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'centralDAO', type: 'address' }], name: 'setCentralDAO', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'centralFundBuffer', type: 'address' }], name: 'setCentralFundBuffer', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'team', type: 'address' }], name: 'setTeam', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [], name: 'symbol', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'totalSupply', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'transfer', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'from', type: 'address' }, { internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'transferFrom', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }], name: 'transferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}];
