import createReducer from 'utils/createReducer';
import { RequestStatus } from 'types';
import { BlockState } from 'types/store/BlockState';
import { BlockActionTypes } from './actionsTypes';
import { blockHandlers } from './handlers';

export const blockInitialState: Readonly<BlockState> = {
  balance: '',
  blocksInfo: [],
  blocksRewards: [],
  blocksRewardsPaid: [],
  blocksMetas: [],

  ui: {
    [BlockActionTypes.GetData]: RequestStatus.INIT,
    [BlockActionTypes.AssignCard]: RequestStatus.INIT,
    [BlockActionTypes.Withdraw]: RequestStatus.INIT,
  },
};

export default createReducer(blockInitialState, blockHandlers);
