import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus,
} from 'types';
import { approveAvatarApi } from 'api';
import { 
  avatarSetStatus, avatarApprove, avatarGetData,
} from 'store/avatar/actionCreators';

export function* avatarApproveSaga({ type, payload }: ReturnType<typeof avatarApprove>) {
  try{
    yield put(avatarSetStatus({ type, status: RequestStatus.REQUEST }));

    yield call(approveAvatarApi, payload.address, true);
    yield put(avatarGetData());

    yield put(avatarSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(avatarSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
