import {
  createStore,
  compose,
  applyMiddleware,
  combineReducers,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistReducer, persistStore } from 'redux-persist';
import { createReduxHistoryContext } from 'redux-first-history';
import storage from 'redux-persist/es/storage';
import { AuthState, MetamaskState } from 'types';
import { createBrowserHistory } from 'history';
import { apiInterceptors } from 'api';
import reducer from './rootReducer';
import rootSaga from './rootSaga';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const metamaskPersistConfig = {
  key: 'metamask',
  storage,
  whitelist: ['isLostWallet'] as Array<keyof MetamaskState>,
};

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['accessToken', 'refreshToken', 'addressWallet'] as Array<keyof AuthState>,
};

const reducers = {
  router: routerReducer,
  ...reducer,
  metamask: persistReducer(metamaskPersistConfig, reducer.metamask),
  auth: persistReducer(authPersistConfig, reducer.auth),
};

const sagaMiddleware = createSagaMiddleware();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
    __REDUX_DEVTOOLS_EXTENSION__: typeof compose;
  }
}

export default (initialState: { [key: string]: never } = {}) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    || window.__REDUX_DEVTOOLS_EXTENSION__
    || compose;

  const store = createStore(
    combineReducers(reducers),
    initialState,
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware,
      ),
    ),
  );

  const history = createReduxHistory(store);

  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);
  apiInterceptors(store);

  return { store, persistor, history };
};
