import 'assets/styles/app.css';
import 'assets/styles/index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Routes } from 'containers';
import { ToastContainer } from 'react-toastify';
import configureStore from 'store/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import 'react-toastify/dist/ReactToastify.css';

const config = configureStore();
export const { store, history, persistor } = config;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}
      >
        <Router history={history}>
          <Routes />
          <ToastContainer
            limit={3}
            theme="dark"
          />
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
