/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useState } from 'react';
import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { linksOpensea } from 'appConstants';
import { Soc1Img, Soc2Img, Soc3Img } from 'assets/img';
import styles from './styles.module.css';

interface LinksProps {
  className?: string
}

export const Links: FC<LinksProps> = ({ className = '' }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleChangeVisible = () => setIsVisible((value) => !value);
  return (
    <div
      className={cx(styles.links, className)}
      onClick={isVisible ? () => setIsVisible(false) : () => {}}
    >
      <a
        href="https://discord.com/invite/xDqt8NT7tA"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={Soc1Img}
          alt="soc1"
          className={styles.image}
        />
      </a>
      <button
        onClick={handleChangeVisible}
      >
        <img
          src={Soc2Img}
          alt="soc2"
          className={styles.image}
        />
      </button>
      <a
        href="https://twitter.com/AwakenedNFT"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={Soc3Img}
          alt="soc3"
          className={styles.image}
        />
      </a>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            key="modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className={styles.modal}
          >
            <a
              href={linksOpensea.MultiPass}
              target="_blank"
              rel="noreferrer"
            >
              MultiPass
            </a>
            <a
              href={linksOpensea.Gears}
              target="_blank"
              rel="noreferrer"
            >
              Gear Tokens
            </a>
            <a
              href={linksOpensea.Avatar}
              target="_blank"
              rel="noreferrer"
            >
              Avatars
            </a>
            <a
              href={linksOpensea.Block}
              target="_blank"
              rel="noreferrer"
            >
              Blocks
            </a>
            <a
              href={linksOpensea.CitizenCard}
              target="_blank"
              rel="noreferrer"
            >
              A-ID Cards
            </a>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
