import { RequestStatus } from 'types';
import { BlockState } from 'types/store/BlockState';
import { BlockActionTypes } from './actionsTypes';

export const blockSetState = (payload: Partial<BlockState>) => ({
  type: BlockActionTypes.SetState,
  payload,
});

export const blockSetStatus = (
  payload: { type: BlockActionTypes, status: RequestStatus },
) => ({
  type: BlockActionTypes.SetStatus,
  payload,
});

export const blockGetData = () => ({
  type: BlockActionTypes.GetData,
});

export const blockAssignCard = (payload: { 
  blockId: number, cardId: number, callback?: () => void,
}) => ({
  type: BlockActionTypes.AssignCard,
  payload,
});

export const blockWithdraw = (payload: { blockId: number }) => ({
  type: BlockActionTypes.Withdraw,
  payload,
});

export const blockRemoveCard = (payload: { 
  blockId: number, cardId: number, callback?: () => void,
}) => ({
  type: BlockActionTypes.RemoveCard,
  payload,
});
