import { ApiEndpoint } from 'appConstants';
import { throwApiError } from 'utils';
import { api } from 'api';

export const apiRewards = async (): Promise<{
  blocks: {
    reward: string,
    blockId: string,
  }[],
  citizenCards: {
    reward: string,
    blockId: string
  }[],
}> => {
  const { data } = await api.get(
    `${ApiEndpoint.Rewards}`,
  );

  if (data.error) {
    throwApiError(data);
  }

  return data;
};
