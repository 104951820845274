import { getAddress, getContract } from 'api/ethereum';
import { GearsAbi } from 'assets/abi';
import { contracts } from 'appConstants';

export const applyingToAvatarGearsApi = async (gearIds: number[], avatarIds: number[]) => {
  const contract = getContract(contracts.Gears, GearsAbi);
  const address = await getAddress();

  return contract.methods
    .applyingToAvatar(gearIds, avatarIds)
    .send({ from: address });
};

export const mintGearsApi = async (multiPassIds: number[]) => {
  const contract = getContract(contracts.Gears, GearsAbi);
  const address = await getAddress();

  return contract.methods
    .mint(multiPassIds)
    .send({ from: address });
};

export const mintAndApplyGearsApi = async (multiPassIds: number[], avatarIds: number[]) => {
  const contract = getContract(contracts.Gears, GearsAbi);
  const address = await getAddress();

  return contract.methods
    .mintAndApply(multiPassIds, avatarIds)
    .send({ from: address });
};

export const balanceOfGearsApi = async (id: number): Promise<string> => {
  const contract = getContract(contracts.Gears, GearsAbi);
  const address = await getAddress(); 

  return contract.methods
    .balanceOf(address, id)
    .call();
};

export const balanceBatchGearsApi = async (ids: number[]): Promise<string> => {
  const contract = getContract(contracts.Gears, GearsAbi);
  const address = await getAddress(); 

  return contract.methods
    .balanceOfBatch(address, ids)
    .call();
};

export const withdrawGearsApi = async (amount: number) => {
  const contract = getContract(contracts.Gears, GearsAbi);
  const address = await getAddress();

  return contract.methods
    .withdraw(amount)
    .send({ from: address });
};
