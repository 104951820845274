import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus, Unwrap,
} from 'types';
import { balanceOfGearsApi, apiProfileGears } from 'api';
import { 
  gearsSetStatus, gearsGetData, gearsSetState,
} from 'store/gears/actionCreators';

export function* gearsGetDataSaga({ type }: ReturnType<typeof gearsGetData>) {
  try{
    yield put(gearsSetStatus({ type, status: RequestStatus.REQUEST }));

    const gearsMetas: Unwrap<typeof apiProfileGears> = yield call(apiProfileGears);

    const balance: Unwrap<typeof balanceOfGearsApi> = yield call(balanceOfGearsApi, 100);

    yield put(gearsSetState({ balance, gearsMetas }));
    
    yield put(gearsSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(gearsSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
