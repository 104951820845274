import {
  decimalPlaces, passwordSign, 
} from 'appConstants';
import { BigNumber } from 'bignumber.js';
import Web3 from 'web3';

export const getWeb3 = () => {
  const web3 = new Web3(Web3.givenProvider);
  return web3;
};

export const getAddress = async (): Promise<string> => {
  const web3 = getWeb3();
  const accounts = await web3.eth.getAccounts();
  return accounts[0];
};

export const getChainId = async (): Promise<number> => {
  const web3 = getWeb3();
  const chainId = await web3.eth.getChainId();
  return chainId;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getContract = (address: string, abi: any) => {
  const web3 = getWeb3();
  const abiFromJson = JSON.parse(JSON.stringify(abi));
  const contract = new web3.eth.Contract(abiFromJson, address);
  return contract;
};

export const getBalanceBnB = async (address: string): Promise<string> => {
  const web3 = getWeb3();
  const result = await web3.eth.getBalance(address);
  return new BigNumber(web3.utils.fromWei(result)).decimalPlaces(decimalPlaces).toString();
};

export const signPersonal = async (message: string): Promise<string> => {
  const web3 = getWeb3();
  const address = await getAddress();

  const signature = await web3.eth.personal.sign(
    web3.utils.utf8ToHex(message),
    address,
    passwordSign,
  );
  return signature;
};

export * from './avatar';
export * from './gears';
export * from './multiPass';
export * from './awakenedToken';
export * from './block';
export * from './citizenCard';
