import { contracts } from './contracts';

export const linksOpensea = {
  MultiPass: process.env.REACT_APP_MULTI_PASS_OPENSEA as string,
  Avatar: process.env.REACT_APP_AVATAR_OPENSEA as string,
  Gears: process.env.REACT_APP_GEARS_OPENSEA as string,
  CitizenCard: process.env.REACT_APP_CITIZEN_CARD_OPENSEA as string,
  Block: process.env.REACT_APP_BLOCK_OPENSEA as string,
};

export const linkOpenseaForItem = process.env.REACT_APP_ITEM_OPENSEA as string;

export const linksOpenseaForItem = {
  MultiPass: `${linkOpenseaForItem}/${contracts.MultiPass}/`,
  Avatar: `${linkOpenseaForItem}/${contracts.Avatar}/`,
  Gears: `${linkOpenseaForItem}/${contracts.Gears}/`,
  CitizenCard: `${linkOpenseaForItem}/${contracts.CitizenCard}/`,
  Block: `${linkOpenseaForItem}/${contracts.Block}/`,
};
