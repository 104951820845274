import { call, put } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus, Unwrap,
} from 'types';
import { 
  balanceOfCitizenCardApi, getCardInfoCitizenCardApi, getRewardsCitizenCardApi, 
  getRewardsPaidCitizenCardApi, apiProfileCitizenCard,
} from 'api';
import { 
  citizenCardSetStatus, citizenCardGetData, citizenCardSetState,
} from 'store/citizenCard/actionCreators';

export function* citizenCardGetDataSaga({ type }: ReturnType<typeof citizenCardGetData>) {
  try{
    yield put(citizenCardSetStatus({ type, status: RequestStatus.REQUEST }));

    const balance: Unwrap<typeof balanceOfCitizenCardApi> = 
      yield call(balanceOfCitizenCardApi);
    
    yield put(citizenCardSetState({ balance }));

    const citizenCardMetas: Unwrap<typeof apiProfileCitizenCard> = 
      yield call(apiProfileCitizenCard);

    const cardIds = citizenCardMetas.map((item) => item.id);

    const cardsInfo: Array<Unwrap<typeof getCardInfoCitizenCardApi>> = 
      yield Promise.all(cardIds.map((cardId) => getCardInfoCitizenCardApi(cardId)));
   
    const cardsRewards: Array<Unwrap<typeof getRewardsCitizenCardApi>> = 
      yield Promise.all(cardIds.map((cardId) => getRewardsCitizenCardApi(cardId)));
    
    const cardsRewardsPaid: Array<Unwrap<typeof getRewardsPaidCitizenCardApi>> = 
      yield Promise.all(cardIds.map((cardId) => getRewardsPaidCitizenCardApi(cardId)));
    
    yield put(citizenCardSetState({ 
      cardsInfo, cardsRewards, cardsRewardsPaid, citizenCardMetas,
    }));

    yield put(citizenCardSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(citizenCardSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
