import createReducer from 'utils/createReducer';
import { RequestStatus } from 'types';
import { MultiPassState } from 'types/store/MultiPassState';
import { MultiPassActionTypes } from './actionsTypes';
import { multiPassHandlers } from './handlers';

export const multiPassInitialState: Readonly<MultiPassState> = {
  isApproveForCitizenCard: false,
  isApproveForAvatar: false,
  isApproveForBlock: false,
  isApproveForGears: false,
  multiPassMetas: [],
  ui: {
    [MultiPassActionTypes.GetData]: RequestStatus.INIT,
    [MultiPassActionTypes.Approve]: RequestStatus.INIT,
  },
};

export default createReducer(multiPassInitialState, multiPassHandlers);
