export const apiBaseUrl = process.env.REACT_APP_API_URL as string;

export enum ApiEndpoint {
  AuthNonce = '/auth/nonce',
  AuthLogin = '/auth/login',
  AuthLogout = '/auth/logout',
  AuthRefresh = '/auth',

  Leaderboard = '/leaderboard',
  GetSignature = '/metadata/get-signature',
  
  ProfileAvatar = '/profile/avatar',
  ProfileBlock = '/profile/block',
  ProfileMultiPass = '/profile/multipass',
  ProfileCitizenCard = '/profile/citizenCard',
  ProfileGear = '/profile/gear',
  
  Rewards = '/profile/rewards',
}
