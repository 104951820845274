import { RequestStatus } from 'types';
import { AvatarState } from 'types/store/AvatarState';
import { AvatarActionTypes } from './actionsTypes';

export const avatarSetState = (payload: Partial<AvatarState>) => ({
  type: AvatarActionTypes.SetState,
  payload,
});

export const avatarSetStatus = (
  payload: { type: AvatarActionTypes, status: RequestStatus },
) => ({
  type: AvatarActionTypes.SetStatus,
  payload,
});

export const avatarGetData = () => ({
  type: AvatarActionTypes.GetData,
});

export const avatarUpdate = (payload: { 
  avatarIds: number[], multiPassIds: number[], callback?: () => void,
}) => ({
  type: AvatarActionTypes.Update,
  payload,
});

export const avatarMint = (payload: { multiPassIds: number[], callback?: () => void }) => ({
  type: AvatarActionTypes.Mint,
  payload,
});

export const avatarApprove = (payload: { address: string }) => ({
  type: AvatarActionTypes.Approve,
  payload,
});
