import { BigNumber } from 'bignumber.js';
import { getAddress, getContract } from 'api/ethereum';
import { AwakenedTokenAbi } from 'assets/abi';
import { contracts, MAX_UINT_256, decimalPlaces } from 'appConstants';

export const getDecimalsAwakenedTokenApi = async (): Promise<number> => {
  const contract = getContract(contracts.AwakenedToken, AwakenedTokenAbi);
  const result = await contract.methods.decimals().call();
  return result;
};

export const balanceOfAwakenedTokenApi = async (): Promise<string> => {
  const contract = getContract(contracts.AwakenedToken, AwakenedTokenAbi);
  const address = await getAddress(); 

  return contract.methods
    .balanceOf(address)
    .call();
};

export const allowanceAwakenedTokenApi = async (spender: string): Promise<string> => {
  const contract = getContract(contracts.AwakenedToken, AwakenedTokenAbi);
  const address = await getAddress(); 

  const result = await contract.methods
    .allowance(address, spender)
    .call();

  const decimals = await getDecimalsAwakenedTokenApi();

  return (
    new BigNumber(result)
      .div(new BigNumber(10).pow(decimals))
      .decimalPlaces(decimalPlaces).toString()
  );
};

export const approveAwakenedTokenApi = async (spender: string, amount: string) => {
  const contract = getContract(contracts.AwakenedToken, AwakenedTokenAbi);
  const address = await getAddress();

  return contract.methods
    .approve(spender, amount)
    .send({ from: address });
};

export const approveAllAwakenedTokenApi = async (spender: string) => 
  approveAwakenedTokenApi(spender, MAX_UINT_256.toString());
