export enum Network {
  GoerliTest = 'GoerliTest',
}

export const networkChains: Record<Network, string> = {
  [Network.GoerliTest]: '0x5',
};

type Chain = {
  chainId: string,
  chainName: string,
  nativeCurrency: {
    name: string,
    symbol: string,
    decimals: number,
  },
  rpcUrls: string[],
  blockExplorerUrls: string[],
};

type Chains = Pick<Record<Network, Chain>,
Network.GoerliTest>;

export const chains: Chains = {
  [Network.GoerliTest]: {
    chainId: networkChains.GoerliTest,
    chainName: 'Goerli Testnet',
    nativeCurrency:
      {
        name: 'GoerliETH',
        symbol: 'GoerliETH',
        decimals: 18,
      },
    rpcUrls: ['https://goerli.infura.io/v3/'],
    blockExplorerUrls: ['https://goerli.etherscan.io'],
  },
};

export const BSC_NETWORKS = [networkChains.GoerliTest];
