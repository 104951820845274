import React, { FC, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import cx from 'classnames';
import { ButtonIcon } from 'components';
import { IconSettingWhiteImg } from 'assets/img';
import ImageBlockImg from './emptyImg.svg';
import styles from './styles.module.css';

interface ImageBlockProps {
  className?: string;
  imageUrl?: string;
  isSmall?: boolean;
  nameBlock?: string;
  actions?: { setting: () => void, play: () => void, refresh: () => void },
}

export const ImageBlock: FC<ImageBlockProps> = ({ 
  className = '', imageUrl, isSmall, actions, nameBlock,
}) => {
  const [isShow, setIsShow] = useState(false);
  const handleShowStart = () => setIsShow(true);
  const handleShowEnd = () => setIsShow(false);
  const imageHoverColor = imageUrl ? 'rgba(224, 70, 114, 0.7)' : 'rgba(0, 0, 0, 0.4)';
  return (
    <AnimatePresence initial={false}>
      <motion.div
        className={cx(styles.imageBlock, { [styles.imageBlockForSmall]: isSmall }, className)}
        onHoverStart={handleShowStart}
        onHoverEnd={handleShowEnd}
        onTapStart={actions?.setting}
      >
        <img
          src={imageUrl || ImageBlockImg}
          alt="ImageBlockImg"
          className={cx(styles.image)}
        />
        {isShow && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ backgroundColor: imageHoverColor, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className={cx(styles.imageBlockActive, { [styles.imageBlockDisabled]: !imageUrl })}
          >
            <div className={styles.containerButton}>
              {imageUrl ? (
                <>
                  <ButtonIcon
                    onClick={actions?.setting}
                    imageURL={IconSettingWhiteImg}
                  />
                  {/* <ButtonIcon
                    onClick={actions?.play}
                    imageURL={IconPlayWhiteImg}
                  />
                  <ButtonIcon
                    onClick={actions?.refresh}
                    imageURL={IconRefreshWhiteImg}
                  /> */}
                </>
              ) : (
                <p>
                  Manage your
                  {' '}
                  {nameBlock || 'NFTs'}
                </p>
              )}
            </div>
          </motion.div>
        )}
      </motion.div>
    </AnimatePresence>
  );
};
