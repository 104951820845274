import React, { FC, useCallback, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TypeButton } from 'components';
import { RequestStatus } from 'types';
import { useAuth } from 'hooks';
import { LogoClaimImg, IconButtonWhiteImg } from 'assets/img';
import { rewardsGetData, rewardsClaimAll } from 'store/rewards/actionCreators';
import { RewardsActionTypes } from 'store/rewards/actionsTypes';
import { rewardsSelectors } from 'store/rewards/selectors';
import styles from './styles.module.css';

type ClaimTokensProps = {
  className?: string,
};

export const ClaimTokens: FC<ClaimTokensProps> = ({ className = '' }) => {
  const dispatch = useDispatch();

  const balance = useSelector(rewardsSelectors.getProp('balanceRewardsAll'));
  const statusClaimAll = useSelector(rewardsSelectors.getStatus(RewardsActionTypes.ClaimAll));

  const {
    isUserAuthenticated, addressWallet,
  } = useAuth();
  
  useEffect(() => {
    if(isUserAuthenticated) {
      dispatch(rewardsGetData());
    }
  }, [dispatch, isUserAuthenticated, addressWallet]);

  const handleClaimAll = useCallback(() => {
    dispatch(rewardsClaimAll());
  }, [dispatch]);

  return (
    <section className={cx(styles.claimTokens, className)}>
      <div className={styles.line} />
      <div className={styles.line2} />
      <span className={styles.title}>
        Claim Tokens
      </span>
      <img
        src={LogoClaimImg}
        className={styles.logo}
        alt="logo"
      />
      <div className={styles.wrapBalance}>
        <div className={styles.leftImg} />
        <span className={styles.labelBalance}>
          BALANCE
        </span>
        <span className={styles.valueBalance}>
          {balance}
        </span>
        <div className={styles.rightImg} />
      </div>
      <Button
        onClick={handleClaimAll}
        className={styles.button}
        icon={IconButtonWhiteImg}
        type={TypeButton.red}
        isLoading={statusClaimAll === RequestStatus.REQUEST}
        isDisabled={!isUserAuthenticated}
      >
        CLAIM ALL
      </Button>
    </section>
  );
};
