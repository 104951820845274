import { getAddress, getContract } from 'api/ethereum';
import { MultiPassAbi } from 'assets/abi';
import { contracts } from 'appConstants';

export const approveMultiPassApi = async (addressApprove: string, isApprove: boolean) => {
  const contract = getContract(contracts.MultiPass, MultiPassAbi);
  const address = await getAddress(); 

  return contract.methods
    .setApprovalForAll(addressApprove, isApprove)
    .send({ from: address });
};

export const isApprovedForAllMultiPassApi = async (operatorAddress: string): Promise<boolean> => {
  const contract = getContract(contracts.MultiPass, MultiPassAbi);
  const address = await getAddress(); 

  return contract.methods
    .isApprovedForAll(address, operatorAddress)
    .call();
};

export const balanceOfMultiPassApi = async (id: number): Promise<string> => {
  const contract = getContract(contracts.MultiPass, MultiPassAbi);
  const address = await getAddress(); 

  return contract.methods
    .balanceOf(address, id)
    .call();
};

export const balanceBatchMultiPassApi = async (ids: number[]): Promise<string> => {
  const contract = getContract(contracts.MultiPass, MultiPassAbi);
  const address = await getAddress(); 

  return contract.methods
    .balanceOfBatch(address, ids)
    .call();
};

export const withdrawMultiPassApi = async (amount: number) => {
  const contract = getContract(contracts.MultiPass, MultiPassAbi);
  const address = await getAddress();

  return contract.methods
    .withdraw(amount)
    .send({ from: address });
};
