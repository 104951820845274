import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus,
} from 'types';
import { applyingToAvatarGearsApi } from 'api';
import { 
  gearsSetStatus, gearsApplyToAvatar,
} from 'store/gears/actionCreators';

export function* gearsApplyToAvatarSaga({ type, payload }: ReturnType<typeof gearsApplyToAvatar>) {
  try{
    yield put(gearsSetStatus({ type, status: RequestStatus.REQUEST }));

    yield call(applyingToAvatarGearsApi, payload.gearIds, payload.avatarIds);
    
    if(payload.callback) payload.callback();

    yield put(gearsSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(gearsSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
