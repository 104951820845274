import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus, Unwrap,
} from 'types';
import { contracts } from 'appConstants';
import { apiProfileAvatar, isApprovedForAllAvatarApi } from 'api';
import { 
  avatarSetStatus, avatarGetData, avatarSetState,
} from 'store/avatar/actionCreators';

export function* avatarGetDataSaga({ type }: ReturnType<typeof avatarGetData>) {
  try{
    yield put(avatarSetStatus({ type, status: RequestStatus.REQUEST }));
    
    const isApproveForCitizenCard: Unwrap<typeof isApprovedForAllAvatarApi> = 
      yield call(isApprovedForAllAvatarApi, contracts.CitizenCard);
    
    const isApproveForGears: Unwrap<typeof isApprovedForAllAvatarApi> = 
      yield call(isApprovedForAllAvatarApi, contracts.Gears);

    const avatarsMetas: Unwrap<typeof apiProfileAvatar> = 
      yield call(apiProfileAvatar);

    yield put(avatarSetState({ 
      avatarsMetas,
      isApproveForCitizenCard,
      isApproveForGears,
    }));

    yield put(avatarSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(avatarSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
