import React, { 
  FC, useState, useCallback, useEffect, memo,
} from 'react';
import { useDeviceSelectors } from 'react-device-detect';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { PaginationOptions } from 'swiper/types';
import {
  Modal, Button, ButtonIcon, TypeButton, ChooseToken, 
} from 'components';
import { IconActiveImg } from 'assets/img';
import styles from './styles.module.css';
import 'swiper/css';
import 'swiper/css/pagination';

interface ChooseItemsModalProps {
  isOnlyOne?: boolean,
  
  isOpen: boolean,
  onClose: () => void,
  title?: string,
  label?: string,
  items: ChooseToken[],
  defaultIds?: number[],
  onChoose?: (ids: number[]) => void,

  buttonLabel: string,
  buttonIsDisabled?: boolean,
  buttonIsLoading?: boolean,
  onButtonClick: (ids: number[]) => void,
  buttonImg?: string,

  buttonSecondLabel?: string,
  buttonSecondIsDisabled?: boolean,
  buttonSecondIsLoading?: boolean,
  onButtonSecondClick?: (ids: number[]) => void,
  buttonSecondImg?: string,

  error?: string,
}

const options: PaginationOptions = {
  clickable: true,
  bulletClass: styles.bullet,
  bulletActiveClass: styles.bullet_active,
};

export const ChooseItemsModal: FC<ChooseItemsModalProps> = memo(({
  isOnlyOne = true,
  isOpen,
  onClose,
  title,
  label,
  items,
  defaultIds,
  onChoose,
  onButtonClick,
  buttonLabel,
  buttonImg,
  buttonIsDisabled,
  buttonIsLoading,
  onButtonSecondClick,
  buttonSecondLabel,
  buttonSecondImg,
  buttonSecondIsDisabled,
  buttonSecondIsLoading,
  error,
}) => {
  const [itemIds, setItemIds] = useState<number[]>([]);
  const [selectors] = useDeviceSelectors(window.navigator.userAgent);
  const { isWindows } = selectors;

  const handleChoose = useCallback((id: number) => () => {
    if (itemIds.includes(id)) {
      setItemIds((state) => {
        const res = [
          ...state.filter((it) => it !== id),
        ];
        if (onChoose) onChoose(res);
        return res;
      }); 
    } else if (isOnlyOne) {
      setItemIds([id]);
      if (onChoose) onChoose([id]);
    } else {
      setItemIds((state) => {
        const res = [
          ...state,
          id,
        ];
        if (onChoose) onChoose(res);
        return res;
      }); 
    }
  }, [isOnlyOne, itemIds, onChoose]);

  const handleCloseModal = useCallback(() => {
    setItemIds([]);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    onButtonClick(itemIds);
  }, [itemIds, onButtonClick]);

  const handleSecondSubmit = useCallback(() => {
    if (onButtonSecondClick) onButtonSecondClick(itemIds);
  }, [itemIds, onButtonSecondClick]);

  useEffect(() => {
    if (defaultIds) setItemIds(defaultIds);
  }, [defaultIds]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      title={title}
    >
      <div className={styles.container}>
        <p className={styles.label}>{label}</p>
        <div className={styles.slider}>
          <Swiper
            slidesPerView={4}
            spaceBetween={15}
            pagination={options}
            modules={[Pagination]}
            className={styles.swiper}
          >
            {items.map(({
              label: itemLabel, img, value, id,
            }) => (
              <SwiperSlide key={itemLabel}>
                <ButtonIcon
                  className={cn(
                    styles.img_button, 
                    { [styles.isActiveItem]: itemIds.includes(id) },
                  )}
                  classNameImage={styles.img}
                  onClick={handleChoose(id)}
                  imageURL={img}
                />
                {itemIds.includes(id) && (
                  <img
                    className={styles.bgActive}
                    src={IconActiveImg}
                    alt="active icon"
                  />
                )}
                <div className={styles.slide_info}>
                  <p className={styles.slide_label}>{itemLabel}</p>
                  <p className={styles.slide_label}>{value}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <p className={styles.error}>{error}</p>

        <div className={styles.containerButton}>
          {onButtonSecondClick && (
            <Button
              className={styles.button}
              classNameIcon={cn(
                styles.buttonIcon, 
                { [styles.buttonIconWindow]: isWindows },
              )}
              onClick={handleSecondSubmit}
              icon={buttonSecondImg}
              type={TypeButton.link}
              isDisabled={buttonSecondIsDisabled}
              isLoading={buttonSecondIsLoading}
            >
              <p className={styles.button_label}>{buttonSecondLabel}</p>
            </Button>
          )}
          <Button
            className={styles.button}
            classNameIcon={cn(
              styles.buttonIcon, 
              { [styles.buttonIconWindow]: isWindows },
            )}
            onClick={handleSubmit}
            icon={buttonImg}
            type={TypeButton.link}
            isDisabled={buttonIsDisabled}
            isLoading={buttonIsLoading}
          >
            <p className={styles.button_label}>{buttonLabel}</p>
          </Button>
        </div>
      </div>
    </Modal>
  );
});
