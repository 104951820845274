import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler, formatEth } from 'utils';
import { apiRewards, getDecimalsAwakenedTokenApi } from 'api';
import {
  RequestStatus, Unwrap,
} from 'types';
import { 
  rewardsSetStatus, rewardsGetData, rewardsSetState,
} from 'store/rewards/actionCreators';

export function* rewardsGetDataSaga({ type }: ReturnType<typeof rewardsGetData>) {
  try{
    yield put(rewardsSetStatus({ type, status: RequestStatus.REQUEST }));
    
    const decimals: Unwrap<typeof getDecimalsAwakenedTokenApi> = 
      yield call(getDecimalsAwakenedTokenApi);

    const rewards: Unwrap<typeof apiRewards> = yield call(apiRewards);

    let balanceRewardsAll = 0; 
    
    rewards.blocks.forEach((item) => {
      balanceRewardsAll += +item.reward;
    });

    rewards.citizenCards.forEach((item) => {
      balanceRewardsAll += +item.reward;
    });

    yield put(rewardsSetState({ rewards, balanceRewardsAll: formatEth(`${balanceRewardsAll}`, decimals) }));

    yield put(rewardsSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(rewardsSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
