import { getAddress, getContract } from 'api/ethereum';
import { CitizenCardAbi } from 'assets/abi';
import { contracts } from 'appConstants';

export const balanceOfCitizenCardApi = async (): Promise<string> => {
  const contract = getContract(contracts.CitizenCard, CitizenCardAbi);
  const address = await getAddress(); 

  return contract.methods
    .balanceOf(address)
    .call();
};

export const getCardInfoCitizenCardApi = async (cardId: number): Promise<{
  level: number, cityId: number, gearId: number, multipassId: number,
}> => {
  const contract = getContract(contracts.CitizenCard, CitizenCardAbi);

  return contract.methods
    .getCardInfo(cardId)
    .call();
};

export const getRewardsCitizenCardApi = async (cardId: number): Promise<number> => {
  const contract = getContract(contracts.CitizenCard, CitizenCardAbi);

  return contract.methods
    .getRewards(cardId)
    .call();
};

export const getRewardsPaidCitizenCardApi = async (cardId: number): Promise<number> => {
  const contract = getContract(contracts.CitizenCard, CitizenCardAbi);

  return contract.methods
    .getRewardsPaid(cardId)
    .call();
};

export const withdrawETHCitizenCardApi = async (member: string, amount: number) => {
  const contract = getContract(contracts.CitizenCard, CitizenCardAbi);
  const address = await getAddress();

  return contract.methods
    .withdrawETH(member, amount)
    .send({ from: address });
};

export const withdrawRewardsCitizenCardApi = async (cardId: number, amount: number) => {
  const contract = getContract(contracts.CitizenCard, CitizenCardAbi);
  const address = await getAddress();

  return contract.methods
    .withdrawRewards(cardId, amount)
    .send({ from: address });
};

export const withdrawAllRewardsCitizenCardApi = async () => {
  const contract = getContract(contracts.CitizenCard, CitizenCardAbi);
  const address = await getAddress();

  return contract.methods
    .withdrawAllRewards()
    .send({ from: address });
};

export const mintWithAvatarCitizenCardApi = async ({
  avatarIds, timestamps, v, r, s,
}: { 
  avatarIds: number[],
  timestamps: number[],
  v: number,
  r: string,
  s: string,
}) => {
  const contract = getContract(contracts.CitizenCard, CitizenCardAbi);
  const address = await getAddress();

  return contract.methods
    .batchMintWithAvatar(avatarIds, timestamps, v, r, s)
    .send({ from: address });
};

export const mintWithMultiPassCitizenCardApi = async (multiPassId: number) => {
  const contract = getContract(contracts.CitizenCard, CitizenCardAbi);
  const address = await getAddress();

  return contract.methods
    .mintWithMultiPass(multiPassId)
    .send({ from: address });
};

export const upgradeCardCitizenCardApi = async (cardIds: number[], multiPassId: number[]) => {
  const contract = getContract(contracts.CitizenCard, CitizenCardAbi);
  const address = await getAddress();

  return contract.methods
    .upgradeCard(cardIds, multiPassId)
    .send({ from: address });
};
