import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus, Unwrap,
} from 'types';
import { balanceOfAwakenedTokenApi } from 'api';
import { 
  awakenedTokenSetStatus, awakenedTokenGetData, awakenedTokenSetState,
} from 'store/awakenedToken/actionCreators';

export function* awakenedTokenGetDataSaga({ type }: ReturnType<typeof awakenedTokenGetData>) {
  try{
    yield put(awakenedTokenSetStatus({ type, status: RequestStatus.REQUEST }));

    const balance: Unwrap<typeof balanceOfAwakenedTokenApi> = yield call(balanceOfAwakenedTokenApi);

    yield put(awakenedTokenSetState({ balance }));
    
    yield put(awakenedTokenSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(awakenedTokenSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
