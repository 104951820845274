import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'types';
import { contracts } from 'appConstants';
import { ChooseItemsModal } from 'components';
import { IconSettingImg } from 'assets/img';
import { gearsApplyToAvatar } from 'store/gears/actionCreators';
import { GearsActionTypes } from 'store/gears/actionsTypes';
import { gearsSelectors } from 'store/gears/selectors';
import { avatarSelectors } from 'store/avatar/selectors';
import { AvatarActionTypes } from 'store/avatar/actionsTypes';
import { avatarApprove } from 'store/avatar/actionCreators';

export const useApplyGears = () => {
  const dispatch = useDispatch();
  
  const statusApplyToAvatar = useSelector(gearsSelectors.getStatus(GearsActionTypes.ApplyToAvatar));
  const statusApprove = useSelector(avatarSelectors.getStatus(AvatarActionTypes.Approve));
  const isApproveForGears = useSelector(avatarSelectors.getProp('isApproveForGears'));

  const gearsMetas = useSelector(gearsSelectors.getProp('gearsMetas'));
  const gears = useMemo(() => gearsMetas.map((item) => ({
    id: item.id,
    label: `// ID ${item.id}`,
    value: item.level ? `// Lvl ${item.level}` : '',
    img: item.imageUrl,
  })), [gearsMetas]); 

  const avatarsMetas = useSelector(avatarSelectors.getProp('avatarsMetas'));

  const avatars = useMemo(() => avatarsMetas
    .filter((item) => item?.level && +item.level >= 2)
    .map((item) => ({
      id: item.id,
      label: `// ID ${item.id}`,
      value: `// Lvl ${item.level}`,
      img: item.imageUrl,
    })), [avatarsMetas]);

  const [avatarIds, setAvatarIds] = useState<number[]>([]);
  const [gearIds, setGearIds] = useState<number[]>([]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [nextStepModal, setNextStepModal] = useState<boolean>(false);
  
  const nextStep = useCallback((gearIdsNew: number[]) => {
    if(gearIdsNew.length > 0) {
      setGearIds(gearIdsNew);
      setOpenModal(false); 
      setNextStepModal(true);
    }
  }, []);

  const handleFistStepOpen = useCallback((
    { avatarId, gearId }: { avatarId?: number, gearId?: number } = {},
  ) => {
    setOpenModal(true); 
    if (avatarId) setAvatarIds([avatarId]);
    if (gearId) setGearIds([gearId]);
  }, []);

  const handleCloseAll = useCallback(() => {
    setOpenModal(false); 
    setNextStepModal(false); 
    setAvatarIds([]);
    setGearIds([]);
  }, []);

  const handleApplyToAvatar = useCallback((avatarIdsNew: number[]) => {
    if(gearIds.length === avatarIdsNew.length) {
      dispatch(gearsApplyToAvatar({
        avatarIds: avatarIdsNew,
        gearIds, 
        callback: handleCloseAll,
      }));
    }
  }, [dispatch, gearIds, handleCloseAll]);

  const handleApprove = useCallback(() => {
    dispatch(avatarApprove({ address: contracts.Gears }));
  }, [dispatch]);

  return {
    openApplyGears: handleFistStepOpen,
    modalApplyGears: [
      <ChooseItemsModal
        key="useApplyGears-Next step"
        buttonLabel="Next step"
        items={gears}
        label="Select the Gear(s) you want to apply below."
        title="APPLY GEAR TOKEN(S)"
        isOpen={openModal}
        onClose={handleCloseAll}
        onButtonClick={nextStep}
        defaultIds={gearIds}
        onChoose={setGearIds}
        buttonIsDisabled={gearIds.length === 0}
      />,
      <ChooseItemsModal
        key="useApplyGears-APPLY GEAR"
        buttonLabel="APPLY GEAR"
        buttonImg={IconSettingImg}
        items={avatars}
        label={`Select the Avatar(s) to which you would 
        like to apply a Gear Token.
        
        You need a level 2 Avatar or above to complete this action.
        `}
        title="APPLY GEAR TOKEN(S)"
        isOpen={nextStepModal}
        onClose={handleCloseAll}
        onButtonClick={handleApplyToAvatar}
        defaultIds={avatarIds}
        onChoose={setAvatarIds}
        buttonIsDisabled={!isApproveForGears || avatarIds.length === 0}
        buttonIsLoading={statusApplyToAvatar === RequestStatus.REQUEST}
        buttonSecondLabel="Approve"
        onButtonSecondClick={!isApproveForGears ? handleApprove : undefined}
        buttonSecondIsDisabled={avatarIds.length !== gearIds.length}
        buttonSecondIsLoading={statusApprove === RequestStatus.REQUEST}
      />,
    ],
  };
};
