import { call, put, select } from 'redux-saga/effects';
import { RequestStatus, Unwrap } from 'types';
import { sagaExceptionHandler } from 'utils';
import {
  apiAuthLogin, apiAuthNonce, getAddress, signPersonal, 
} from 'api';
import { authSelectors } from 'store/auth/selectors';
import { authLogin, authSetState, authSetStatus } from 'store/auth/actionCreators';

export function* authLoginSaga({
  type,
}: ReturnType<typeof authLogin>) {
  try {
    yield put(authSetStatus({ type, status: RequestStatus.REQUEST }));

    const address: Unwrap<typeof getAddress> = yield call(getAddress);
    const walletAddressSaved: string = yield select(authSelectors.getProp('addressWallet'));

    if (address !== walletAddressSaved) {
      const {
        token,
      }: Unwrap<typeof apiAuthNonce> = yield call(apiAuthNonce, address);

      let signature: Unwrap<typeof signPersonal>;
      try {
        signature = yield call(signPersonal, token);
      } catch {
        throw new Error('User denied transaction signature');
      }

      const {
        accessToken,
        refreshToken,
      }: Unwrap<typeof apiAuthLogin> = yield call(apiAuthLogin, token, signature);

      yield put(authSetState({
        accessToken,
        refreshToken,
        addressWallet: address,
      }));
    }

    yield put(authSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(authSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
