import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus,
} from 'types';
import { approveAllAwakenedTokenApi } from 'api';
import { 
  awakenedTokenSetStatus, awakenedTokenApprove,
} from 'store/awakenedToken/actionCreators';
import { contracts } from 'appConstants';

export function* awakenedTokenApproveSaga({ type }: ReturnType<typeof awakenedTokenApprove>) {
  try{
    yield put(awakenedTokenSetStatus({ type, status: RequestStatus.REQUEST }));

    yield call(approveAllAwakenedTokenApi, contracts.MultiPass);

    yield put(awakenedTokenSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(awakenedTokenSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
