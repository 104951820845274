export default [{
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'uint256', name: 'multipassId', type: 'uint256', 
  }, {
    indexed: false, internalType: 'address', name: 'holder', type: 'address', 
  }, {
    indexed: false, internalType: 'uint256', name: 'comicId', type: 'uint256', 
  }],
  name: 'ApplyComic',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'uint256', name: 'multipassId', type: 'uint256', 
  }, {
    indexed: false, internalType: 'address', name: 'holder', type: 'address', 
  }, {
    indexed: false, internalType: 'uint256', name: 'gearId', type: 'uint256', 
  }],
  name: 'ApplyGear',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'account', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'operator', type: 'address', 
  }, {
    indexed: false, internalType: 'bool', name: 'approved', type: 'bool', 
  }],
  name: 'ApprovalForAll',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'operator', type: 'address', 
  }, {
    indexed: false, internalType: 'bool', name: 'hasApproval', type: 'bool', 
  }],
  name: 'DefaultApproval',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'uint256', name: 'id', type: 'uint256', 
  }, {
    indexed: false, internalType: 'address', name: 'to', type: 'address', 
  }, {
    indexed: false, internalType: 'uint256', name: 'timestamp', type: 'uint256', 
  }],
  name: 'Mint',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'previousOwner', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'newOwner', type: 'address', 
  }],
  name: 'OwnershipTransferred',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'baseTokenID', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint8', name: 'maxMintingPerTime', type: 'uint8', 
  }],
  name: 'SetMaxMintingPerTime',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'baseTokenID', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint8', name: 'mintingMode', type: 'uint8', 
  }],
  name: 'SetMintingMode',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'operator', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'from', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'to', type: 'address', 
  }, {
    indexed: false, internalType: 'uint256[]', name: 'ids', type: 'uint256[]', 
  }, {
    indexed: false, internalType: 'uint256[]', name: 'values', type: 'uint256[]', 
  }],
  name: 'TransferBatch',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'operator', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'from', type: 'address', 
  }, {
    indexed: true, internalType: 'address', name: 'to', type: 'address', 
  }, {
    indexed: false, internalType: 'uint256', name: 'id', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint256', name: 'value', type: 'uint256', 
  }],
  name: 'TransferSingle',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'string', name: 'value', type: 'string', 
  }, {
    indexed: true, internalType: 'uint256', name: 'id', type: 'uint256', 
  }],
  name: 'URI',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'uint256', name: 'id', type: 'uint256', 
  }, {
    indexed: false, internalType: 'uint8', name: 'level', type: 'uint8', 
  }, {
    indexed: false, internalType: 'uint256', name: 'timestamp', type: 'uint256', 
  }],
  name: 'UpdateLevel',
  type: 'event', 
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: '_operator', type: 'address', 
  }, {
    indexed: false, internalType: 'uint256', name: '_ethWei', type: 'uint256', 
  }],
  name: 'WithdrawEth',
  type: 'event', 
}, { stateMutability: 'payable', type: 'fallback' }, {
  inputs: [], name: 'Initialize', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint32', name: 'multipassId', type: 'uint32' }, { internalType: 'address', name: 'holder', type: 'address' }, { internalType: 'uint32', name: 'comicId', type: 'uint32' }], name: 'applyComic', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint32', name: 'multipassId', type: 'uint32' }, { internalType: 'address', name: 'holder', type: 'address' }, { internalType: 'uint16', name: 'gearId', type: 'uint16' }], name: 'applyGear', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'account', type: 'address' }, { internalType: 'uint256', name: 'id', type: 'uint256' }], name: 'balanceOf', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address[]', name: 'accounts', type: 'address[]' }, { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' }], name: 'balanceOfBatch', outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: '_baseTokenID_', type: 'uint256' }], name: 'baseTokenExists', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: '_owner', type: 'address' }, { internalType: 'uint16', name: 'id', type: 'uint16' }], name: 'burn', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [], name: 'contractURI', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'pure', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: '_addr', type: 'address' }, { internalType: 'uint256', name: '_id', type: 'uint256' }], name: 'existsToken', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: '_tokenBaseID', type: 'uint256' }, { internalType: 'address', name: '_addr', type: 'address' }], name: 'getInvitationCode', outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }], name: 'getTokenLevel', outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: '_addr', type: 'address' }], name: 'grantAdmin', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint32', name: 'multipassId', type: 'uint32' }], name: 'hasApplyComic', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint32', name: 'multipassId', type: 'uint32' }], name: 'hasApplyGear', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'initialize', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: '_owner', type: 'address' }, { internalType: 'address', name: '_operator', type: 'address' }], name: 'isApprovedForAll', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint8', name: 'amount', type: 'uint8' }, { internalType: 'uint32', name: 'invitationCode', type: 'uint32' }], name: 'mintMultiPass', outputs: [], stateMutability: 'payable', type: 'function', 
}, {
  inputs: [{ internalType: 'address[]', name: '_addrs', type: 'address[]' }], name: 'mintMultiPassTo', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }], name: 'mintedTimestamp', outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'name', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'pure', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'nftTokens', outputs: [{ internalType: 'uint8', name: 'maxMintingPerTime', type: 'uint8' }, { internalType: 'uint8', name: 'mintingMode', type: 'uint8' }, { internalType: 'uint16', name: 'nftCost', type: 'uint16' }, { internalType: 'uint16', name: 'totalSupply', type: 'uint16' }, { internalType: 'uint16', name: 'maxSupply', type: 'uint16' }, { internalType: 'uint32', name: 'currentMintedID', type: 'uint32' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }], name: 'ownerOf', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: '_addr', type: 'address' }], name: 'revokeAdmin', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'from', type: 'address' }, { internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' }, { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }, { internalType: 'bytes', name: 'data', type: 'bytes' }], name: 'safeBatchTransferFrom', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'from', type: 'address' }, { internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'id', type: 'uint256' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }, { internalType: 'bytes', name: 'data', type: 'bytes' }], name: 'safeTransferFrom', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'operator', type: 'address' }, { internalType: 'bool', name: 'approved', type: 'bool' }], name: 'setApprovalForAll', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'operator', type: 'address' }, { internalType: 'bool', name: 'hasApproval', type: 'bool' }], name: 'setDefaultApproval', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: '_baseTokenID_', type: 'uint256' }, { internalType: 'uint8', name: '_maxMintingPerTime_', type: 'uint8' }], name: 'setMaxMintingPerTime', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: '_baseTokenID_', type: 'uint256' }, { internalType: 'uint16', name: '_supply', type: 'uint16' }], name: 'setMaxSupplies', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: '_baseTokenID_', type: 'uint256' }, { internalType: 'uint8', name: '_mode', type: 'uint8' }], name: 'setMintingMode', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: '_baseTokenID_', type: 'uint256' }, { internalType: 'uint16', name: '_howManyFinney', type: 'uint16' }], name: 'setNftCost', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: '_baseTokenID_', type: 'uint256' }, { internalType: 'uint8', name: '_mintingMode_', type: 'uint8' }, { internalType: 'uint16', name: '_supply_', type: 'uint16' }, { internalType: 'uint16', name: '_nftCost_', type: 'uint16' }, { internalType: 'uint8', name: '_maxMintingPerTime_', type: 'uint8' }, { internalType: 'uint32[5]', name: '_upgradeconditions_', type: 'uint32[5]' }], name: 'setTokenBaseInfo', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }, { internalType: 'uint8', name: 'level', type: 'uint8' }], name: 'setTokenLevel', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'string', name: '_uri', type: 'string' }, { internalType: 'uint256', name: '_id', type: 'uint256' }], name: 'setTokenURI', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: '_baseTokenID_', type: 'uint256' }, { internalType: 'uint8', name: 'level', type: 'uint8' }, { internalType: 'uint32', name: 'condition', type: 'uint32' }], name: 'setUpdateCondition', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'string', name: 'newuri', type: 'string' }], name: 'setUri', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }], name: 'supportsInterface', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [], name: 'symbol', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'pure', type: 'function', 
}, {
  inputs: [{ internalType: 'uint16', name: 'id', type: 'uint16' }],
  name: 'tokenInfo',
  outputs: [{
    components: [{ internalType: 'uint8', name: 'level', type: 'uint8' }, { internalType: 'uint8', name: 'isRarible', type: 'uint8' }, { internalType: 'uint16', name: 'gearID', type: 'uint16' }, { internalType: 'uint32', name: 'comicID', type: 'uint32' }, { internalType: 'uint32', name: 'mintedAtTimestamp', type: 'uint32' }, { internalType: 'address', name: 'tokenMinter', type: 'address' }], internalType: 'struct Awknd.TokenMinted', name: '', type: 'tuple', 
  }],
  stateMutability: 'view',
  type: 'function', 
}, {
  inputs: [], name: 'totalSupply', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }], name: 'transferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'uri', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function', 
}, {
  inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'withdraw', outputs: [], stateMutability: 'nonpayable', type: 'function', 
}, { stateMutability: 'payable', type: 'receive' }];
