import React, { FC, useEffect, useCallback } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { IconButtonImg } from 'assets/img';
import { Button } from 'components';
import { useAuth } from 'hooks';
import { RequestStatus, MetamaskStatus } from 'types';
import { metamaskConnect, metamaskDisconnect } from 'store/metamask/actionCreators';
import { rewardsGetData, rewardsClaimAll } from 'store/rewards/actionCreators';
import { RewardsActionTypes } from 'store/rewards/actionsTypes';
import { rewardsSelectors } from 'store/rewards/selectors';
import styles from './styles.module.css';

interface ActionsProps {
  className?: string
}

export const Actions: FC<ActionsProps> = ({ className = '' }) => {
  const dispatch = useDispatch();
  const statusClaimAll = useSelector(rewardsSelectors.getStatus(RewardsActionTypes.ClaimAll));

  const {
    isUserAuthenticated, isLoading, addressWallet,
  } = useAuth();

  useEffect(() => {
    if(isUserAuthenticated) {
      dispatch(rewardsGetData());
    }
  }, [dispatch, isUserAuthenticated, addressWallet]);

  const handleClaimAll = useCallback(() => {
    dispatch(rewardsClaimAll());
  }, [dispatch]);

  const handleConnect = useCallback(() => {
    dispatch(metamaskConnect());
  }, [dispatch]);

  const handleDisconnect = useCallback(() => {
    dispatch(metamaskDisconnect({ status: MetamaskStatus.LOST }));
  }, [dispatch]);

  return (
    <div className={cx(styles.actions, className)}>
      <Button
        onClick={isUserAuthenticated ? handleDisconnect : handleConnect}
        isLoading={isLoading}
        className={styles.button}
        icon={IconButtonImg}
      >
        {isUserAuthenticated ? 'DISCONNECT' : 'CONNECT WALLET'}
      </Button>
      <Button
        onClick={handleClaimAll}
        className={styles.button}
        icon={IconButtonImg}
        isLoading={statusClaimAll === RequestStatus.REQUEST}
        isDisabled={!isUserAuthenticated}
      >
        CLAIM TOKENS
      </Button>
    </div>
  );
};
