import { api } from 'api';
import { ApiEndpoint } from 'appConstants';
import { throwApiError } from 'utils';

export const getSignature = async ({
  avatarIds, walletAddress, 
}: {
  avatarIds: number[],
  walletAddress: string,
}): Promise<{
  signature: {
    r: string,
    s: string,
    v: number
  },
  timestamps: number[]
}> => {
  const { data } = await api.post(
    `${ApiEndpoint.GetSignature}`,
    {
      avatarIds, batch: true, walletAddress,
    },
  );

  if (data.error) {
    throwApiError(data);
  }

  return {
    timestamps: data.stamps,
    signature: data.signature,
  };
};
