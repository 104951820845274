import React, {
  useCallback, useEffect, useMemo, useState, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'types';
import { ChooseItemsModal } from 'components';
import { IconRefreshImg } from 'assets/img';
import { avatarUpdate } from 'store/avatar/actionCreators';
import { AvatarActionTypes } from 'store/avatar/actionsTypes';
import { avatarSelectors } from 'store/avatar/selectors';
import { multiPassSelectors } from 'store/multiPass/selectors';

export const useUpdateAvatar = () => {
  const dispatch = useDispatch();
  
  const statusUpdate = useSelector(avatarSelectors.getStatus(AvatarActionTypes.Update));
  const avatarsMetas = useSelector(avatarSelectors.getProp('avatarsMetas'));

  const avatars = useMemo(() => avatarsMetas.map((item) => ({
    id: item.id,
    label: `// ID ${item.id}`,
    value: `// Lvl ${item.level}`,
    img: item.imageUrl,
  })), [avatarsMetas]);

  const multiPassMetas = useSelector(multiPassSelectors.getProp('multiPassMetas'));

  const [avatarIds, setAvatarIds] = useState<number[]>([]);
  const [multiPassIds, setMultiPassIds] = useState<number[]>([]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [nextStepModal, setNextStepModal] = useState<boolean>(false);

  const [error, setError] = useState('');
  
  const multiPass = useMemo(() => {
    const avatarSelected = avatarsMetas.find((item) => item.id === avatarIds[0]);
    if (avatarSelected) {
      return multiPassMetas
        .filter((item) => item?.level 
          && avatarSelected?.level 
          && +item.level > +avatarSelected.level)
        .map((item) => ({
          id: item.id,
          label: `// ID ${item.id}`,
          value: `// Lvl ${item.level}`,
          img: item.imageUrl,
        }));
    } 
    return [];
  }, [avatarIds, avatarsMetas, multiPassMetas]);
  
  const nextStep = useCallback((avatarIdsNew: number[]) => {
    if(avatarIdsNew.length > 0) {
      setAvatarIds(avatarIdsNew);
      setOpenModal(false); 
      setNextStepModal(true);
    }
  }, []);

  const handleFistStepOpen = useCallback((
    { avatarId, multiPassId }: { avatarId?: number, multiPassId?: number } = {},
  ) => {
    if(avatarId) {
      setAvatarIds([avatarId]);
      setNextStepModal(true);
    } else if (multiPassId) {
      setMultiPassIds([multiPassId]);
      setOpenModal(true); 
    } else {
      setOpenModal(true); 
    }
  }, []);

  const handleCloseAll = useCallback(() => {
    setOpenModal(false); 
    setNextStepModal(false); 
    setAvatarIds([]);
    setMultiPassIds([]);
  }, []);

  const handleUpdate = useCallback((multiPassIdsNew: number[]) => {
    if(avatarIds.length === multiPassIds.length && !error) {
      dispatch(avatarUpdate({
        avatarIds,
        multiPassIds: multiPassIdsNew, 
        callback: handleCloseAll,
      }));
    }
  }, [avatarIds, dispatch, error, handleCloseAll, multiPassIds.length]);

  useEffect(() => {
    if (avatarsMetas.length && multiPassMetas.length && avatarIds.length && multiPassIds.length) {
      const avatarMeta = avatarsMetas.find(({ id }) => avatarIds.includes(id));
      const multiMeta = multiPassMetas.find(({ id }) => multiPassIds.includes(id));
      const maxLevelUpgrade = '4';
      if (
        avatarMeta?.level === maxLevelUpgrade &&
        multiMeta?.level === maxLevelUpgrade
      ) {
        return setError("You can't upgrade an avatar of the same level");
      }
    }
    setError('');
  }, [avatarIds, avatarsMetas, multiPassIds, multiPassMetas]);

  return {
    openUpdateAvatar: handleFistStepOpen,
    modalUpdateAvatar: [
      <ChooseItemsModal
        key="useUpdateAvatar-Next step"
        buttonLabel="Next step"
        items={avatars}
        label="Select the Avatar(s) you want to upgrade below."
        title="UPGRADE AVATARS"
        isOpen={openModal}
        onClose={handleCloseAll}
        onButtonClick={nextStep}
        defaultIds={avatarIds}
        isOnlyOne={false}
        onChoose={setAvatarIds}
        buttonIsDisabled={avatarIds.length === 0 || !!error}
        error={error}
      />,
      <ChooseItemsModal
        key="useUpdateAvatar-Upgrade AVATAR"
        buttonLabel="Upgrade AVATAR"
        buttonImg={IconRefreshImg}
        items={multiPass}
        label="Select the MultiPass(s) you want to burn below."
        title="Select the MultiPass(s)"
        isOpen={nextStepModal}
        onClose={handleCloseAll}
        onButtonClick={handleUpdate}
        defaultIds={multiPassIds}
        isOnlyOne={false}
        onChoose={setMultiPassIds}
        buttonIsDisabled={avatarIds.length !== multiPassIds.length || !!error}
        buttonIsLoading={statusUpdate === RequestStatus.REQUEST}
        error={error}
      />,
    ],
  };
};
