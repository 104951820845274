import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus, Unwrap,
} from 'types';
import { contracts } from 'appConstants';
import { isApprovedForAllMultiPassApi, apiProfileMultiPass } from 'api';
import { 
  multiPassSetStatus, multiPassGetData, multiPassSetState,
} from 'store/multiPass/actionCreators';

export function* multiPassGetDataSaga({ type }: ReturnType<typeof multiPassGetData>) {
  try{
    yield put(multiPassSetStatus({ type, status: RequestStatus.REQUEST }));
    
    const isApproveForCitizenCard: Unwrap<typeof isApprovedForAllMultiPassApi> = 
      yield call(isApprovedForAllMultiPassApi, contracts.CitizenCard);
    const isApproveForAvatar: Unwrap<typeof isApprovedForAllMultiPassApi> = 
      yield call(isApprovedForAllMultiPassApi, contracts.Avatar);
    const isApproveForBlock: Unwrap<typeof isApprovedForAllMultiPassApi> = 
      yield call(isApprovedForAllMultiPassApi, contracts.Block);
    const isApproveForGears: Unwrap<typeof isApprovedForAllMultiPassApi> = 
      yield call(isApprovedForAllMultiPassApi, contracts.Gears);

    const multiPassMetas: Unwrap<typeof apiProfileMultiPass> = 
      yield call(apiProfileMultiPass);

    yield put(multiPassSetState({
      isApproveForCitizenCard,
      isApproveForAvatar,
      isApproveForBlock,
      isApproveForGears,
      multiPassMetas,
    }));
    yield put(multiPassSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(multiPassSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
