import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus, Unwrap,
} from 'types';
import { 
  withdrawRewardsBlockApi, getRewardsBlockApi,
} from 'api';
import { 
  blockSetStatus, blockAssignCard,
} from 'store/block/actionCreators';

export function* blockWithdrawSaga({ type, payload }: ReturnType<typeof blockAssignCard>) {
  try{
    yield put(blockSetStatus({ type, status: RequestStatus.REQUEST }));

    const balanceRewards: Unwrap<typeof getRewardsBlockApi> = 
      yield call(getRewardsBlockApi, payload.blockId);
    
    yield call(withdrawRewardsBlockApi, payload.blockId, balanceRewards);
    yield put(blockSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(blockSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
