import { ActionFn } from 'types/redux';
import { BlockState } from 'types/store/BlockState';
import { blockSetState, blockSetStatus } from './actionCreators';
import { BlockActionTypes } from './actionsTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BlockHandlerFn<F extends (...args : any) => any> = ActionFn<BlockState, ReturnType<F>>;

const setState: BlockHandlerFn<typeof blockSetState> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: BlockHandlerFn<typeof blockSetStatus> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const blockHandlers = {
  [BlockActionTypes.SetState]: setState,
  [BlockActionTypes.SetStatus]: setStatus,
};
