import rewards from './rewards';
import leader from './leader';
import auth from './auth';
import block from './block';
import citizenCard from './citizenCard';
import awakenedToken from './awakenedToken';
import gears from './gears';
import multiPass from './multiPass';
import avatar from './avatar';
import metamask from './metamask';

export default {
  rewards,
  leader,
  auth,
  block,
  citizenCard,
  awakenedToken,
  gears,
  multiPass,
  avatar,
  metamask,
};
