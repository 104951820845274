import React, { FC, useEffect, useMemo } from 'react';
import { NftList, Welcome } from 'components';
import { ClaimTokens } from 'containers';
import { useDispatch, useSelector } from 'react-redux';
import {
  useAuth, useApplyGears,
} from 'hooks';
import {
  ArrowImg, 
} from 'assets/img';
import { gearsGetData } from 'store/gears/actionCreators';
import { gearsSelectors } from 'store/gears/selectors';
import { avatarGetData } from 'store/avatar/actionCreators';
import { linksOpensea, linksOpenseaForItem } from 'appConstants';

export const Gears: FC = () => {
  const dispatch = useDispatch();

  const { isUserAuthenticated, addressWallet } = useAuth();
  const { modalApplyGears, openApplyGears } = useApplyGears();

  const gearsMetas = useSelector(gearsSelectors.getProp('gearsMetas'));

  useEffect(() => {
    if (isUserAuthenticated) {
      dispatch(gearsGetData());
      dispatch(avatarGetData());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUserAuthenticated, addressWallet]);

  const linksGear = useMemo(() => [
    { name: 'Apply Gear tokens', method: () => openApplyGears() },
    { 
      name: 'Sell Gear tokens', 
      method: () => window.open(
        `${linksOpensea.Gears}/`, 
        '_blank',
      ),
    },
  ], [openApplyGears]);

  const actionsGear = useMemo(() => [
    { nameAction: 'APPLY GEAR', imgAction: ArrowImg, action: (id?: number) => openApplyGears({ gearId: id }) },
    { 
      nameAction: 'SELL GEAR TOKEN', 
      imgAction: ArrowImg, 
      action: (id?: number) => window.open(
        `${linksOpenseaForItem.Gears}/${id}`, 
        '_blank',
      ),
    },
  ], [openApplyGears]);

  return (
    <>
      <Welcome 
        title="Gear Tokens" 
        description={`
        Easily manage your Gear Tokens with the Gear Token module. Apply, sell and track your tokens, and customize your Avatar's weapons and equipment for enhanced performance and increased rarity.
      `}
      />
      <ClaimTokens />
      <NftList
        key="GEAR TOKENS"
        name="GEAR TOKENS"
        titleModal="GEAR TOKENS"
        isDisabledLinks={!isUserAuthenticated || !gearsMetas.length}
        links={linksGear} 
        images={gearsMetas}
        actions={actionsGear}
      />
      {modalApplyGears}
    </>
  );
};
