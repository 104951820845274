import { BigNumber } from 'bignumber.js';

export * from './api';
export * from './routes';

export * from './network';
export * from './notifications';
export * from './contracts';
export * from './opensea';

export const decimalPlaces = 2;
export const MAX_UINT_256 = new BigNumber('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');

export const passwordSign = 'test password!';
