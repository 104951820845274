import { call, put } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus, Unwrap,
} from 'types';
import { 
  mintWithAvatarCitizenCardApi, getSignature, getAddress,
} from 'api';
import { avatarGetData } from 'store/avatar/actionCreators';
import { 
  citizenCardSetStatus, citizenCardMint, citizenCardGetData,
} from 'store/citizenCard/actionCreators';

export function* citizenCardMintSaga({ type, payload }: ReturnType<typeof citizenCardMint>) {
  try{
    yield put(citizenCardSetStatus({ type, status: RequestStatus.REQUEST }));
    const walletAddress: Unwrap<typeof getAddress> = yield call(getAddress);

    const {
      signature, timestamps,
    }: Unwrap<typeof getSignature> = yield getSignature({
      avatarIds: payload.avatarIds,
      walletAddress,
    });

    yield call(mintWithAvatarCitizenCardApi, {
      avatarIds: payload.avatarIds,
      v: signature.v,
      s: signature.s,
      r: signature.r,
      timestamps,
    });

    yield put(citizenCardGetData());
    yield put(avatarGetData());
    yield put(citizenCardSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(citizenCardSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
